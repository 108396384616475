@media(max-width: 4000px) {
    .new-menu img{ display: none;}
    
}

@media(max-width: 1299px) {
    .menu-fulldetails .menu-fulldetails-row .menu-items {
        overflow-y: scroll;
    }
    section.profile-page-details.container.tabContent{ max-width: 960px;}
    .menu-fulldetails .menu-fulldetails-row .menu-cash-total .delivery-collection {
        overflow-y: scroll;
    }
    .review-section .card{ width: 593px; text-align: center;}
}
@media(max-width: 1180px) {
    .tab-content .menu-offer{ width: 600px; background-color: var(--color-light);}
    .review-section .card{ width: 636px;}
    .no-data-open.card {
        text-align: center;
        max-width: 100%;
        width: 642px;
    }

}

@media(max-width: 1024px) {
     .switch-toggle .button input{ padding: 0;}
    .switch-toggle p{ font-size: 12px;}
    .business_collection_radio_delivery{ font-size: 12px;}
    .banner-details.col-lg-8{ margin-top: -12px;}
    .no-data-open.card {
        text-align: center;
        max-width: 100%;
        width: 642px;
    }
    .menu-banner .banner-main .banner-logo-details .banner-details h1{ font-size: 1.5rem;}
    .banner{ background-image: url(../images/banner/responsive-banner-mobile.png);}
    .review-section .card {
        width: 614px;
    }
    

    .banner .search-bar {
        top: 62%;left: 50%; max-width: 800px; background-color: var(--color-light);
    }
    .banner .search-bar h1{ color: var(--color-c1);}
    header .navbar-expand-md .navbar-brand .mail-phone-div {
        margin: 0;
    }
    .banner .search-bar p{ color: var(--color-dark);}

    header .navbar-expand-md .navbar-collapse .menu-part a {
        padding: 5px 5px;
    }

    .mail-phone-div {
        bottom: 0;
    }

    .total-listing-section .search-listing .card-system .card .card-row .img-div .love {
        right: 11%;
    }
    .tab-content .menu-offer{ width: 634px;}
}
@media(max-width: 932px) {
    .header li a{ padding: 0 10px;}
    .menu-banner .banner-main .banner-logo-details .logo-sec img{ width: auto;}
}

@media(max-width: 915px) {
    .mobileCartPopup.open {
        display: block;
        overflow: scroll;
       
    }
    .mobileCartPopup {
        display: none;
        position: fixed;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        overflow: hidden;
        z-index: 999;
        background: #fff;
        transition: all .3s cubic-bezier(0, 1.5, .5, 1);
        
    }
    .mobileCartPopup.open{
        display: block;
    }

    .mobileCartPopup .holder {
        background: var(--color-light);
    }

    .mobileCartPopup .closer {
        display: block;
        position: fixed;
        right: 5px;
        top: 5px;
        font-size: 24px;
        color: #fff;
        border-radius: 50%;
        width: 26px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        z-index: 970;
    }

    .mobileCartPopup .bdrr {
        border: 0;}
        .mobileCartPopup .switch-toggle{ margin-top: 30px;}    

    .menu-fulldetails .menu-fulldetails-row .menu-details-all .menu-offer .search-bar .form-group input{ margin-top: 10px;}
    .new-menu img{ display: none;}
    .header li a{ padding: 0 10px;}
    .tab-content .menu-offer{ padding: 10px;
        box-shadow: none;
        border: 1px solid #efefef;}
    .banner{ background-image: url(../images/banner/responsive-banner-mobile.png); height: 800px;}
    .no-data-open.card {
        text-align: center;
        max-width: 546px;
        width: 100%;
    }
    .review-section .card {
        width: 535px;
    }
    .tab-content .menu-offer {
        width: 536px;
    }

    .total-listing-section-row{ padding: 20px;
       
    }
    section.profile-page-details.container.tabContent {
        max-width: 860px;
    }
    .inner-banner h1 span{ font-size: 2rem;}
    .total-listing-section select{ width: 100%;}
    .total-listing-section textarea{ width: 100%;}
    .total-listing-section .box{ padding: 10px; text-align: center;}
    .order-details-section{text-align: left;}
   
    .total-listing-section .box .fldarea input{ width: 100%;}
    .total-listing-section .container {
        padding: 10px;
    }
    .container.profile.container.total-listing-section{ padding:0 30px 100px 30px;}
    input.radio{ width: auto;}
    .menu-fulldetails .menu-fulldetails-row .menu-items {
        position: static;
        margin-top: 20px;
    }
    input.ng-dirty.ng-valid.ng-touched {
        width: 100%;
    }

    .menu-fulldetails .menu-fulldetails-row .menu-items ul {
        border: 1px solid var(--all);
    }

    .menu-fulldetails {
        padding-top: 0;
    }

    .menu-fulldetails .menu-fulldetails-row .menu-cash-total .delivery-collection {
        position: static;
    }

    .total-listing-section .deet {
        padding: 0 0 30px 0;
        margin: 0;
    }
    .total-listing-section .deet p{ line-height: 20px;}
}


@media(max-width: 896px) {
    .mobileCartPopup .menu-cash-total {
        display: block;
    }
    p.menu-responsive.book{ margin-top: 1.1px !important;}
    .go-to-checkout-modal .left-modal h5{ text-align: left;}
    .my-order-details a{ margin: 0;}
    .my-order-details h3 {
        margin: 0;
    }
    .my-order-details .total-listing-section .box{ padding: 0;}
    .item-button{ display: flex; justify-content: center;}
    .profile-page-details .profile-tab-body .account-prefference .body .profile-details h6 span{ font-size: 1rem;}
    .profile-page-details .profile-tab-body .account-prefference .body .profile-details h6{ font-size: 1rem;}
    .menu-banner .banner-main .banner-logo-details .logo-sec img{ width: auto;}
    .new-menu img{ display: none;}
    .checkout-all .right-panel .send-me-offer{ margin-top: 20px;}
    .checkout-all .checkout .checkout-bg{ padding: 10px;}
    .checkout-all .right-panel .send-me-offer .form-check .form-check-input:checked{ display: block; width: auto;}
    .checkout-all .button-sec .back{ margin-top: 10px;}
    section.Successfull-page.total-listing-section{ padding: 0;}
    footer .footer-bootom{ margin-top: 50px;}
    header .navbar-expand-md{background: linear-gradient(to left, #e73c5f, #000000); border-top: none;}
    header .navbar-expand-md .navbar-toggler .navigation-toggle-icon{border-bottom: 3px solid var(--color-light);}
    header .navbar-expand-md .navbar-toggler .navigation-toggle-icon:before, header .navbar-expand-md .navbar-toggler .navigation-toggle-icon:after{border-top: 3px solid var(--color-light);}
    .review-section .card {
        width: 854px; padding: 10px;
    }
    .review-section .card{
        .text{ text-align: center;
        a{ font-size: 1.2rem;}
        }
        p{ font-weight: 600;}
    }
    .profile-page-details .middle-panel.col-lg-7{ width: 100%;}
    .profile-page-details .right-panel.col-lg-3{ width: 100%; text-align: center;}
    .review-section .card{ margin-bottom: 20px;}
    form.myform .fldarea{ text-align: left;}
    .fldarea.custom-checkbox.business input {
        width: auto;
    }
    .fldarea.report-filedarea.business{ text-align: left;}
    .checkout-all .checkout .checkout-bg{ margin-bottom: 10px;}
    .total-listing-section .box .fldarea h3{ font-size: 1.3rem;}
    .business-sign input{ width:;}
    .row.mt-2.business-sign input{ width: auto;}
 
    .inner-banner h1{ font-size: 2rem;}
    .detail p {
        text-align: left;
    }
    .card-system .container {
        padding: 0;
    }
    .order-tracking{ width: 50% !important;}
    .order-tracking::before{ left: auto !important;}
    .order-details-section td{ font-size: 0.8rem;}
    .freedish-green{font-size: 0.8rem;}
    .detail.text-right p{ text-align: center !important;}
    .accordion-contianer button {
        text-align: left;
    }
    .profile-tab-head{ height: 40px;}
    .profile-page-details .profile-tab-head{ padding: 0;}
    .profile-page-details .profile-tab-head{ display: flex; margin: 0 auto;}

    

    .tab-content .menu-offer {
        padding-top: 10px;
    }

    footer .foot-privacy .foot-link {
        justify-content: center;
    }

    .col-lg-6.foot-copy p {
        text-align: center;
    }

    .mail-phone-div {
        display: none;
    }

    .container {
        padding: 0;
    }

    .floating-button-menu {
        display: block;box-shadow: none; border-radius: 15px;
        .floating-button-menu .floating-button-menu-label h5 span img {
            display: none;
        }
        .banner .search-bar {
            top: 69%;left: 50%;
        }

        footer::before {
            top: -20%;
        }

        footer .foot-privacy .foot-copy {
            text-align: center;
        }

        footer .foot-privacy .foot-copy p {
            margin: 0;
        }

        footer .foot-privacy .foot-link {
            justify-content: center;
        }

        .total-listing-section .search-listing .card-system .card .card-row .img-div::after {
            top: -8%;
        }

        .total-listing-section .filter-section .accordion-container {
            margin-bottom: 20px;
        }

        .total-listing-section .filter-section {
            display: none;
        }

        .total-listing-section .search-listing .location-sort .sort-part a h6 {
            display: none;
        }

        .total-listing-section .search-listing .location-sort .sort-part .filter-mobile {
            display: block;
        }

        .total-listing-section .search-listing .location-bar2 {
            display: block;
        }
    }

    .menu-fulldetails .menu-fulldetails-row .menu-details-all .menu-tabs li button img {
        display: block;
    }

    .menu-fulldetails .menu-fulldetails-row .menu-details-all .menu-tabs .crd-display {
        display: block;
    }

    .container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .banner .search-bar {
        top: 69%;
        width: 96%;background-color: var(--color-light);
    }

    .banner .search-bar h1 {
        font-size: 2rem; color: var(--color-c1);
        
    }

    .banner .search-bar p{ color: var(--color-dark);}
   

    footer .foot-second .foot-social .social-div {
        margin: 15px 0 0 0;
    }

    footer .logo-sec a img {
        height: 44px;
    }

    .total-listing-section .search-listing .card-system .card .card-row .img-div::after {
        top: -8%;
    }

    .checkout-all .left-panel .time-part-selection .address-form-add .address-form {
        flex-direction: column;
    }

    .checkout-all .left-panel .time-part-selection .address-form-add .address-form .form-check {
        width: 100%;
    }

    .checkout-all-row {
        margin-top: 15px;
    }

    .total-listing-section .search-listing .search-bar .form-group svg {
        left: 2%;
    }

    .total-listing-section {
        padding-bottom: 100px;
    }

    .modal-content {
        width: 100%;
        height: 100%;
        margin: 0;
        top: 0;
        left: 0;
    }

    .profile-page-details .profile-tab-head {
        position: fixed;
        width: 100%;
        bottom: 0;
        z-index: 99;
        left: 0;
        flex-direction: row;
        border-radius: 0;
    }

    .profile-page-details .profile-tab-head li a h6 {
        display: none;
    }

    .profile-page-details .profile-tab-head li {
        width: 25%;
    }

    .profile-page-details .profile-tab-head li a {
        justify-content: center; display: flex;
    }

    .profile-page-details .profile-tab-body {
        width: 100%;
        margin: 0;
    }

    .profile-page-details .review-section {
        width: 100%;
        margin: 0px 0 0 0;
    }

    .profile-page-details .info-details {
        width: 100%;
        margin: 0;
    }

    .profile-page-details .book-a-table {
        width: 100%;
        margin: 0;
    }

    .profile-page-details .review-section .card .middle-panel .text p {
        font-size: 13px;
        margin: 0;
        color: var(--color-dark);
        text-align: center;
    }

    .profile-page-details .review-section .card .left-panel {
        height: auto;
        width: 107px;
        display: table;
        margin: 0 auto;
    }

    .profile-page-details .info-details .card .left-panel {
        height: 127px;
        width: 127px;
        display: table;
        margin: 0 auto;
    }

    .profile-page-details .info-details .card .middle-panel .text p {
        text-align: center;
    }
    .profile-page-details {
        padding: 0px 20px 90px 20px;
    }
    .profile-page-details .profile-tab-body .profile-section .body .profile-details h6 span {
        font-size: 15px;
    }
    .profile-page-details .profile-tab-body .account-prefference .head h3 {
        font-size: 17px;
    }
    .profile-page-details .profile-tab-body .profile-section .head h3 {
        font-size: 17px;
    }
    .profile-page-details .profile-tab-body .profile-section .body .profile-update form label .profile-pic span {
        padding-bottom: 0.5em;
    }
    .profile-page-details .profile-tab-body .profile-section .body .profile-update form label .profile-pic {
        height: 90px;
        width: 90px;
    }
    .profile-page-details .profile-tab-body .password .head h3 {
        font-size: 17px;
    }

    .profile-page-details .profile-tab-body .password .body .profile-details h6 span {
        font-size: 13px;
    }
    .profile-page-details .profile-tab-body .password .head a {
        font-size: 12px;
    }
    .profile-page-details .profile-tab-body .account-prefference .head a {
        font-size: 12px;
    }
    .profile-page-details .profile-tab-body .profile-section .head a {
        font-size: 12px;
    }
    .profile-page-details .profile-tab-body .address-book .body .address-div .card {
        width: 100%;
    }
    .profile-page-details .profile-tab-body .no-data-sec h3 {
        font-size: 17px;
    }
    .menu-fulldetails .menu-fulldetails-row .menu-items {
        display: none;
    }
    #delivery-holder {
        order: 1;
        position: static;
    }
    #menu-2 {
        order: 2;
    }
    .menu-fulldetails {
        padding: 20px 0 100px 0;
    }
    .menu-fulldetails .menu-fulldetails-row .menu-details-all .menu-tabs {
        position: fixed;
        width: 100%;
        bottom: 0;
        z-index: 2;
        left: 0;
        flex-direction: row;
        border-radius: 0;
        background: #ffffff;
        padding: 0;
    }
    .menu-fulldetails .menu-fulldetails-row .menu-details-all .menu-tabs li .nav-link img {
        display: inline-block;
        width: 25px;
    }
    .menu-fulldetails .menu-fulldetails-row .menu-details-all .menu-tabs li .nav-link p {
        display: none;
    }
    .menu-fulldetails .menu-fulldetails-row .menu-details-all .menu-tabs li {
        width: 20%;
    }
    .menu-fulldetails .menu-fulldetails-row .menu-details-all .menu-tabs li .nav-link {
        width: 100%;
        display: block;
        justify-content: center;
        padding: 6px 6px 0 6px;
        border-radius: 0; border-right: 1px solid #ccc; margin: 5px 0 5px 0; border-bottom: none;
       
    }
    /* .menu-fulldetails .menu-fulldetails-row .menu-details-all .menu-tabs li .nav-link.active {
         background-color: var(--color-c1);
        border-radius: 0; 
    } */
    .menu-fulldetails .menu-fulldetails-row .menu-details-all .menu-tabs li .nav-link .white {
        display: none;
    }
    .menu-fulldetails .menu-fulldetails-row .menu-details-all .menu-tabs li .nav-link.active .white {
        display: inline-block;
    }
    .menu-fulldetails .menu-fulldetails-row .menu-details-all .menu-tabs li button.active .black {
        display: none;
    }
 
    .menu-fulldetails .menu-fulldetails-row .menu-details-all .menu-tabs .crd-display {
        display: block;
    }

    .menu-cash-total {
        display: none;
    }
    .content a{ margin: 10px 5px 20px 0;}
}


@media(max-width:896px){
    footer .footer-bottom1 p{ display: none;}
    footer .footer-bottom1 h6{ font-size: 1.2rem; margin: 0 0 5px 0;}
    footer .footer-bottom1{ padding-top: 10px;}
   
    p.menu-responsive{ display: block !important; font-size: 11px !important; font-weight: 600;}
    .total-listing-section img{ object-fit: contain; width: 120px; height: 120px;}
    .order-id{ display: flex; justify-content: center;
    p{ margin: 0 10px 0 0;}}
    .menu-fulldetails .menu-fulldetails-row .menu-details-all .menu-tabs li button.active{ border-bottom: none; border-right: 1px solid #ccc;}
 
     .checkout-all .checkout{ padding-bottom: 0;}
     .tab-content .menu-offer{ width: 100%;}
     .header{ padding-bottom: 5px; padding-top: 5px;}
     .modal-dialog .modal-content .modal-header{ justify-content: space-between;}
     .banner .search-bar{ top: 69%;}
     #business-mobile-cart-view{ display: block;}
     div#myProfile{ margin-top: 0;}
     .switch-toggle p {
         font-size: 12px;
     }
     .switch-toggle.button input{ padding-left: 0;}
     .switch-toggle.add-business .add-business{ margin-bottom: 10px;}
     .listing-banner{/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#700523+0,960726+100 */
         background: linear-gradient(to bottom,  #700523 0%,#960726 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
         }
         .menu-banner .banner-main .banner-logo-details{ text-align: center; padding-bottom: 20px;}
         .menu-banner .banner-main .banner-logo-details .banner-details .star-details{ justify-content: center;}
         .header .menu{ padding-right: 25px;}
 }

@media(max-width: 768px) {
    .switch-toggle.add-business{ display: block;}
    .modal-body.myform p {
        color: #000;
    }
    .menu-banner .banner-main .banner-logo-details .logo-sec img{ width: auto;}
    .menu-fulldetails .menu-fulldetails-row .menu-details-all .menu-tabs { margin: 0; background-color: var(--all);border-radius: 0px 0 10px 10px;
    border-top: 1px solid #ccc;}
    .new-menu img{ display: none;}
    .inner-banner{ height: 17vh;}
    .tab-details{ width: 100%;}
    .profile-page-details .row{ display: block; width: auto;}
    .menu-cash-total {
        display: none;
    }
    .my-order-details h2 {
        text-align: center;
    }
  

    .menu-fulldetails .menu-fulldetails-row .menu-items {
        position: static;
    }

    .menu-fulldetails .menu-fulldetails-row .menu-details-all .menu-offer .search-bar {
        margin-top: 0;
    }


    .menu-fulldetails .menu-fulldetails-row .menu-items {
        position: static;
    }

    footer .foot-privacy .foot-link a {
        margin: 0 5px;
    }
    .detail p{ text-align: left;}
    .detail.text-right p {
        text-align: center !important;
       
    }

    ul.menu{ position: absolute; top: 0; right: 0;}
    .header img{ padding-top: 0; padding-bottom: 0;}
    .header li { padding: 7px;}
    #business-mobile-cart-view{ display: block;}
    div#myProfile{ margin-top: 0;}
  
}


@media(max-width: 767px) {
 
    .condition{ position: relative; bottom: 0;}
    .fldarea.custom-checkbox2 .switch-toggle{ display: block;}
    span.verify{ margin: 0 0 0 10px;}
    .review { margin-top: 80px;}
    .info-section{ margin-top: 80px;}
    .book-section{ margin-top: 100px;}
    .switch-toggle.add-business{ display: block;}
  
    .menu-fulldetails .menu-fulldetails-row .menu-details-all .menu-offer .accordian-part .search-section .search-top .svg-search{ margin: -23px 0 0;}

    .listing-banner{/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#700523+0,960726+100 */
        background: linear-gradient(to bottom,  #700523 0%,#960726 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }

    .tab-content .menu-offer{ margin-top: 110px;}
    .menu-banner .banner-main {
        padding-bottom: 0;
       
    }
    .menu-banner .banner-main .banner-logo-details {
        padding-top: 10px; padding-bottom: 5px; margin-top: 0;
    }
    .menu-banner .banner-main .banner-logo-details .banner-details .star-details{ justify-content: center;}
    section#banner{background-position: left;
    }
    .banner-logo-details.row{
        margin-top: 20px; text-align: center;
    }
    #menu-2{ margin-top: -100px;}
   
    .menu-banner .banner-main .banner-logo-details .banner-details p{ color: var(--color-light); text-shadow: none;}
    .new-menu img{ display: block;}
    .modal-dialog .modal-content .modal-header{ justify-content: space-between;}
    .checkout-all {
        padding: 0px 0 100px 0;
    }
    .banner .search-bar {
        top: 76%;
        padding: 12px 15px;
    }
   
    .banner{height: 500px;}
   
    .banner .search-bar p {
        font-size: 15px;
    }
    .banner .search-bar .input-section form .form-group input {
        height: 58px;
    }
    .banner .search-bar .input-section form a {
        padding: 12px 42px;
        font-size: 18px;
    }
    .banner .search-bar .input-section form .form-group img {
        height: 30px;
        width: 30px;
    }
    footer .foot-second .foot-anchor .foot-single-link a {
        font-size: 15px;
    }
    footer .foot-logo-card .total-card img {
        width: 30px;
        height: 30px;
    }
    footer .foot-privacy .foot-copy p {
        font-size: 15px;
    }
    footer .foot-privacy .foot-link a {
        font-size: 15px;
    }
    .total-listing-section .search-listing .card-system .card .card-row .img-div::after {
        top: -5%;
    }
    .total-listing-section .search-listing .card-system .card .card-row .body-div .card-body {
        padding: 10px 0 0 ;
    }
    .total-listing-section .filter-section .accordion-container .accordion-title {
        padding: 10px;
    }
    .total-listing-section .search-listing .location-sort .loca-part h6 {
        font-size: 20px;
    }
    .total-listing-section .search-listing .location-sort .sort-part a h6 {
        font-size: 20px;
    }
    .checkout-all .right-panel .freebies-discount table .left {
        width: 80%;
    }
    .checkout-all .right-panel .freebies-discount table .right {
        width: 20%;
    }
    .Successfull-page {
        padding: 90px 0;
    }
    .Successfull-page .order-sucess-msg h3 {
        font-size: 17px;
    }
    .Successfull-page .order-img-details .image-order .img-details-odr .location-div img {
        width: 12px;
        height: 12px;
    }
    .Successfull-page .order-img-details .image-order .img-details-odr h3 {
        font-size: 17px;
    }
    .Successfull-page .order-img-details .order-id-details .order-id h6 {
        font-size: 12px;
    }
    .Successfull-page .order-img-details .order-id-details .order-id p {
        font-size: 12px;
    }
    .Successfull-page .order-img-details .order-id-details .order-status h6 {
        font-size: 12px;
    }
    .Successfull-page .order-img-details .order-id-details .order-status p {
        font-size: 12px;
    }
    .Successfull-page .order-img-details .image-order .img-details-odr .location-div p {
        font-size: 10px;
    }
    .Successfull-page .order-sucess-msg p {
        font-size: 10px;
    }
    .Successfull-page .order-img-details .image-order .image-div img {
        width: 90px;
        height: 90px;
    }
    .Successfull-page .order-summery h3 {
        font-size: 17px;
    }
    .Successfull-page .order-summery p {
        font-size: 10px;
    }
    .Successfull-page .your-order-sec h4 {
        font-size: 18px;
    }
    .Successfull-page .your-order-sec .order-div .order p {
        font-size: 13px;
    }
    .Successfull-page .order-img-details .image-order {
        align-items: flex-start;
    }
    .Successfull-page .order-img-details .order-id-details .order-id .delivery img {
        height: 16px;
        width: 16px;
    }
    .Successfull-page .order-img-details .order-id-details .order-id .collection img {
        height: 16px;
        width: 16px;
    }
    .total-listing-section {
        padding-bottom: 30px;
    }
    .menu-banner .banner-main .banner-tag p {
        font-size: 10px;
    }
    .menu-banner .banner-main .banner-logo-details .logo-sec img {
        width: 100px;
        height: 100px;
    }
    .menu-banner .banner-main .banner-logo-details .banner-details h1 {
        font-size: 18px; margin-top: 5px; margin-bottom: 0;
    }

}

@media(max-width: 667px) {
    .menu-banner .banner-main {
        padding-bottom: 0;
       
    }


}

@media(max-width: 414px) {


    .banner .search-bar {
        top: 68%; background-color: var(--color-light);
    }
    header .navbar-expand-md .navbar-brand .mail-phone-div a {
        font-size: 12px;
    }
       .banner .search-bar h1 {
        font-size: 18px;
    }
    .banner .search-bar p {
        font-size: 12px;
    }
    .banner .search-bar .input-section form .form-group input {
        height: 50px;
        padding: 10px 130px 10px 39px;
    }
    .banner .search-bar .input-section form a {
        font-size: 13px;
    }
    .banner .search-bar .input-section form .form-group img {
        height: 26px;
        width: 26px;
        left: 3%;
        top: 24%;
    }
    header .navbar-expand-md .navbar-brand .logo-part a img {
        width: 100px;
    }
    footer .foot-second .foot-anchor .foot-single-link a {
        font-size: 10px;
    }
    footer .foot-privacy .foot-copy p {
        font-size: 10px;
    }
    footer .foot-privacy .foot-link a {
        font-size: 10px;
    }
    .total-listing-section .search-listing .card-system .card .card-row .img-div::after {
        top: -7%;
    }
    .total-listing-section .search-listing .card-system .card .card-row .body-div .card-body::after {
        top: 10%;
    }
    .total-listing-section .filter-section .location-bar .img-location p {
        font-size: 14px;
    }
    .total-listing-section .filter-section .location-bar a {
        font-size: 13px;
    }
    .total-listing-section .search-listing .location-sort .loca-part h6 {
        font-size: 17px;
    }
    .total-listing-section .search-listing .location-sort .sort-part a h6 {
        font-size: 17px;
    }
}

@media(max-width: 375px) {
    .listing-banner{/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#700523+0,960726+100 */
        background: linear-gradient(to bottom,  #700523 0%,#960726 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }

 
}