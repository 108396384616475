// @import url("./assets/css/variable.scss");
@import url("./assets/css/variable.scss");
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Qwitcher+Grypen:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Outfit:wght@100..900&display=swap');

/* Use body the variables */
$myColor: black;
$fontsize: 1rem;

@mixin display-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@mixin display-2 {
    display: flex;
    align-items: center;
    justify-content: left;
}
@mixin size($size) {
    width: $size;
    height: $size;
}
.container {
    width: 100%;
    max-width: 1272px;
    margin: 0 auto;

}

a {
    text-decoration: none;
}

// header section start here
.banner {
    position: relative;
    background-image: url(./assets/images/banner/b1.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;

    .search-bar {
        position: absolute;
        left: 30%;
        top: 25%;
        transform: translate(-50%, -50%);
        padding: 20px 25px;

        max-width: 500px;
        width: 100%;
        border-radius: 15px;

        h1 {
            color: var(--color-light);
            text-align: left;
            font-weight: 600;
            text-transform: capitalize;
            margin-bottom: 0;
            font-size: 3rem;
        }

        p {
            color: var(--color-light);
            text-align: left;
            margin-bottom: 7px;
            text-transform: capitalize;
            font-weight: 400;
            margin-bottom: 15px;
        }

        .input-section {
            form {
                position: relative;

                .form-group {
                    input {
                        padding: 10px 167px 10px 59px;
                        font-size: 18px;
                        border-radius: 10px;
                        height: 73px;
                        box-shadow: none;
                        border: 1px solid var(--line);

                        &::placeholder {
                            font-size: 22px;
                        }
                    }
                    img {
                        height: 34px;
                        width: 34px;
                        position: absolute;
                        left: 2%;
                        top: 24%;
                    }
                }
                a {
                    background: linear-gradient(to right, #a60825 0%, #f03157 100%);
                    padding: 16px 44px;
                    color: var(--color-light);
                    position: absolute;
                    right: 4px;
                    top: 5%;
                    border-radius: 10px;
                    font-size: 22px;
                    text-decoration: none;
                }
            }
        }
    }
}

// footer start here
footer {
    background-image: url(./assets/images/footer/footer-bg.png);
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    position: relative;
    background-color: #000;

    .footer-bootom {
        background-image: url(./assets/images/footer/yup.png);
        background-repeat: no-repeat;
    }

    .footer-bottom1 {
        background-color: var(--ass);
        position: relative;
        bottom: 34px;
        padding: 30px 0 20px;

        img.footer-logo {
            width: 127px;
        }

        h6 {
            font-size: 1.6rem;
            color: var(--color-pink1);
            margin-bottom: 10px;
            font-weight: 300;
        }

        p {
            color: var(--all);
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            color: var(--color-light);
        }

        ul li {
            margin: 0px 0 0px 0;
            font-weight: 300;
        }

        ul li a {
            color: var(--all);
        }

        svg {
            fill: var(--color-c1);
            background-color: #1c1c1c;
            padding: 3px;
            margin: 0 10px 0px 0;
            border-radius: 50px;
            width: 20px;
            height: 20px;
        }

        .total-card img {
            width: 54px;
            margin: 6px 10px 5px 0;
            object-fit: cover;
            background: #1c1c1c;
            height: 40px;
            object-fit: contain;
            padding: 5px 5px 5px 5px;
            border-radius: 5px;
            border: 1px var(--ass)
        }

    }

    .logo-sec {
        a {
            display: table;
            margin: 0 auto;
            height: 40px;

            img {
                height: 60px;
            }
        }
    }

    .foot-second {
        .foot-anchor {
            .foot-single-link {
                a {
                    text-decoration: none;
                    color: var(--color-light);
                    font-size: 18px;
                    text-align: left;
                }
            }

        }

        .foot-social {
            .social-div {
                display: flex;
                align-items: center;
                justify-content: left;
                flex-direction: column;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    padding: 5px 20px;
                    -moz-column-gap: 10px;
                    column-gap: 10px;
                    text-decoration: none;
                    border-radius: 30px;
                    width: 200px;

                    svg {
                        fill: var(--color-light);
                    }

                    p {
                        color: var(--color-light);
                        margin: 0;

                    }

                    &:hover {
                        background-color: var(--ass);
                    }
                }
            }
        }

    }

    .foot-logo-card {
        margin-top: 25px;

        h6 {
            color: var(--color-light);
        }

        .total-card {
            display: flex;
            align-items: center;
            justify-content: space-around;
            position: relative;
            margin: 10px 0;
            padding: 10px 0;

            &::after {
                content: "";
                background: url(./assets/images/footer/strip.png) no-repeat center 0px;
                position: absolute;
                top: -3%;
                left: -9px;
                display: block;
                height: 1px;
                width: 100%;
            }

            &::before {
                content: "";
                background: url(./assets/images/footer/strip.png) no-repeat center 0px;
                position: absolute;
                bottom: -3%;
                left: -9px;
                display: block;
                height: 1px;
                width: 100%;
            }

            img {
                width: 50px;
                height: 50px;
            }
        }
    }

    .foot-privacy {
        .foot-copy {
            text-align: left;
            color: var(--color-light);

            p {
                a {
                    text-decoration: none;
                    color: var(--color-light);
                }
            }
        }

        .foot-link {
            display: flex;
            align-items: center;
            justify-content: right;
            font-size: 15px;

            a {
                text-decoration: none;
                margin: 0 10px;
                color: var(--color-c1);
            }

            span {
                color: var(--color-c1);
            }
        }
    }
}

// footer end here

// modal1-navigation start here
.modal1-navigation {
    .modal-dialog {
        margin: 40px 10px 10px 60px;
        .modal-header {
            border-bottom: none;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            button {
                position: absolute;
                top: -3%;
                right: 10%;
                background-color: var(--color-c1);
                border-radius: 50%;
                opacity: 1;
                border: 3px solid var(--color-light);
                color: var(--color-light);
                background: transparent url("./assets/images/modal1-navigation/x.png") center/1em auto no-repeat;

                button.close {
                    background-color: var(--color-light);
                   
                }
            }
            
        }

        .modal-footer {
            border-top: none;
        }

        .modal-body {
            padding-top: 0;

            ul {
                padding: 0;
                margin: 0;

                li {
                    list-style: none;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    column-gap: 10px;
                    padding: 10px 10px 10px 20px;
                    border-bottom: 1px solid var(--modal);
                    padding-left: 0;

                    a {
                        font-size: 15px;
                        font-weight: 400;
                        text-transform: uppercase;
                        color: var(--color-dark);
                        display: flex;
                        align-items: center;
                        justify-content: end;
                        column-gap: 7px;

                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }
    }
}

// modal1-navigation end here

// modal1-register start here
.modal1-register {
    // top: 39px;
    z-index: 99999;
    .modal-dialog {
        .modal-content {
            border: none;
            background-color: transparent;
            .modal-header {
                background-color: var(--color-c1);
                border-bottom: 1px solid var(--modal);
                border-top: 5px solid var(--color-c1);

                h3 {
                    color: var(--color-c1);
                    font-weight: 600;
                    text-transform: capitalize;
                    display: table;
                    margin: 0 auto;
                }

                button {
                    position: absolute;
                    top: 3%;
                    right: 2%;
                    border-radius: 50%;
                    opacity: 1;
                    border: 3px solid var(--color-light);
                    color: var(--color-light);
                    box-shadow: none;
                }
            }
            .modal-footer {
                border-top: none;
                display: block;
                padding: 0;

                h6 {
                    color: var(--color-light);
                    display: table;
                    margin: 0 auto;
                    line-height: 35px;
                }

                .lower-div {
                    background-color: var(--color-light);
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    border-radius: 3px;
                    margin: 0;

                    a {
                        img {
                            height: 30px;
                            width: 30px;
                        }
                    }
                }
            }

            .modal-body {
                background-color: var(--color-light);

                h5 {
                    font-weight: 400;
                    color: var(--color-dark);

                    span {
                        color: var(--color-c1);
                    }
                }

                form {
                    button {
                        background-color: var(--color-c1);
                        width: 100%;
                        color: var(--color-light);
                        text-transform: uppercase;
                    }

                    a {
                        color: var(--color-dark);
                        font-size: 18px;
                        display: table;
                        margin: 10px auto 0;
                    }

                    .form-check-input {
                        &:checked {
                            background-color: var(--color-c1);
                            border-color: var(--color-c1);
                            position: relative;
                            bottom: 3px;
                        }
                    }
                }

                p {
                    color: var(--color-dark);
                    text-align: left;
                }

                .guest {
                    background-color: var(--color-c1);
                    width: 100%;
                    color: var(--color-light);
                    text-transform: uppercase;
                }

                .new-book {
                    text-align: center;
                    color: var(--color-dark);

                    a {
                        color: var(--color-c1);
                        text-decoration: underline;
                    }
                }
            }
        }

    }
}

// modal1-register end here

// filter part modal start here
.modal-filter {
    .filter-section {
        .location-bar {
            padding: 8px 25px;
            background-color: var(--filter);
            border-radius: 40px;
            .img-location {
                display: flex;
                align-items: center;
                justify-content: left;
                column-gap: 10px;

                img {
                    width: 18px;
                    height: 18px;
                }

                p {
                    margin: 0
                }

                a {
                    padding-left: 27px;
                    color: var(--color-dark);
                }
            }

            .accordion-container {
                width: 100%;

                .accordion-title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px;
                    font-size: 1.25em;
                    font-weight: normal;
                    color: #fff;
                    cursor: pointer;

                    .left-panel {
                        color: var(--color-dark);
                    }

                    .right-panel {
                        a {
                            color: var(--color-dark);
                            text-decoration: none;
                            padding: 2px 10px;
                            background-color: var(--filter);
                            border-radius: 30px;
                            font-size: medium;
                        }
                    }
                }

                .accordion-content {
                    padding: 0;

                    form {
                        background-color: var(--color-light);

                        a {
                            text-decoration: none;

                            &:hover {
                                .form-check {
                                    border-color: var(--color-c1);

                                    input#exampleCheck1 {
                                        width: auto;
                                    }

                                    .check-div {
                                        svg {
                                            transform: translateZ(0) scale(1);
                                            opacity: 1;
                                            width: 16px;
                                            min-width: 16px;
                                            margin-right: 8px;
                                            fill: var(--color-c1);
                                        }


                                        p {
                                            color: var(--color-c1);
                                        }
                                    }

                                    p {
                                        color: var(--color-c1);
                                    }

                                }
                            }

                            .form-check {
                                margin: 5px 0;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding: 8px 16px;
                                border-radius: 30px;
                                background-color: var(--color-light);
                                border: 1px solid #d8d8d8;
                                cursor: pointer;

                                .check-div {
                                    display: flex;
                                    align-items: center;
                                    justify-content: left;
                                    column-gap: 3px;

                                    svg {
                                        transition: transform .2s ease-in-out,
                                            opacity .2s ease-in-out,
                                            width .2s ease-in-out,
                                            min-width .2s ease-in-out,
                                            margin-right .2s ease-in-out;
                                        align-self: center;
                                        opacity: 0;
                                        width: 0;
                                        min-width: 0;
                                        transform: translateZ(0) scale(0) rotate(-45deg);
                                    }

                                    p {
                                        color: var(--color-dark);
                                    }
                                }

                                p {
                                    margin: 0;
                                    color: var(--color-dark);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// filter part modal end here

// total filter-listing body start here
.total-listing-section {
    padding-bottom: 150px;

    img {
        width: 100%;
        border-radius: 10px;
        height: auto;
        object-fit: cover;
    }

    .deet {
        padding: 0px 0px 50px;
        margin-top: 30px;
        text-align: center;

        p {
            line-height: 30px;
            font-size: 1rem;
        }

        h2 {
            color: var(--color-dark1);
            font-size: 1.6rem;
        }

        span {
            color: var(--color-c1);
            text-transform: capitalize;
            font-weight: 900;
        }
    }

    .box {
        padding: 20px;
        border: 1px solid #ebebeb;
        border-radius: 10px;
        box-shadow: 0 4px 6px 0 rgba(27, 35, 36, 0.02), 0 2px 12px -2px rgba(27, 35, 36, 0.08), 0 3px 6px 0 rgba(27, 35, 36, 0.06);
        background-color: var(--color-light);
        text-align: left;

        .link {
            svg {
                width: 18px;
                height: 18px;
                margin: 0 10px 0 0;
                fill: var(--color-c1)
            }

            p {
                a {
                    color: var(--color-dark);
                }
            }
        }

        .fldarea input {
            padding: 7px;
            border: 1px solid var(--gray1); border-radius: 5px;
        }

        .fldarea h3 {
            color: var(--color-c1);
            font-size: 1.6rem;
        }

        h6 {
            font-size: 1.2rem;
        }

        label {
            font-size: 1.1rem;
        }
    }

    .myform {

        input.input-form {
            width: 100%;
        }

    }

    select {
        padding: 10px; width: 100%; border: 1px solid var(--gray1); border-radius: 5px;

    }

    textarea {
        height: 100px;
        padding: 10px;
        border: 1px solid var(--gray1); border-radius: 5px; resize: none;
    }

}

input {
    padding: 10px;

    .radio {
        position: absolute;
        margin: 6px 0 0 -82px;
    }
}

.custom-checkbox {
    display: block;
    position: relative;
}

.report-filedarea {
    margin-bottom: 10px;
    align-items: center;

    .alert.alert-danger {
        padding: 0;
        background-color: var(--color-light);
        border: none;
        color: var(--color-red);
        margin-top: 10px;
        margin-bottom: 0;
    }

    .business-signup {
        width: 100%;
    }
}

.filter-section {
    .location-bar {
        padding: 8px 25px;
        background-color: var(--filter);
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        background: linear-gradient(to right, #ff6581 0%, #f03157 100%);


        .img-location {
            display: flex;
            align-items: center;
            justify-content: left;
            column-gap: 10px;

            img {
                width: 18px;
                height: 18px;
                color: var(--color-light);
            }

            p {
                margin: 0;
                color: var(--color-light);
            }

            
        }

        a {
            padding-left: 13px;
            color: var(--color-light);
            font-size: 14px;
        }
    }

    .accordion-container {
        width: 100%;

        .accordion-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            font-size: 1.25em;
            font-weight: normal;
            color: #fff;
            cursor: pointer;

            .left-panel {
                color: var(--color-dark);
            }

            .right-panel {
                a {
                    color: var(--color-dark);
                    text-decoration: none;
                    padding: 2px 10px;
                    background-color: var(--filter);
                    border-radius: 30px;
                    font-size: medium;
                    border: 1px solid #c1c1c1;
                }
            }
        }

        .accordion-content {
            padding: 0;

            form {
                background-color: var(--body);

                a {
                   
                    text-decoration: none;

                    &:hover {
                        .form-check {
                            border-color: var(--color-c1);

                            .check-div {

                                svg {
                                    transform: translateZ(0) scale(1);
                                    opacity: 1;
                                    width: 16px;
                                    min-width: 16px;
                                    margin-right: 8px;
                                    fill: var(--color-c1);
                                }

                                p {
                                    color: var(--color-c1);
                                }
                            }

                            p {
                                color: var(--color-c1);
                            }
                        }
                    }

                    .form-check {
                        margin: 5px 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 8px 16px;
                        border-radius: 5px;
                        background-color: var(--color-light);
                        border: 1px solid #d8d8d8;

                        .check-div {
                            display: flex;
                            align-items: center;
                            justify-content: left;
                            column-gap: 3px;

                            img {
                                width: 35px;
                                height: 35px;
                                object-fit: contain;
                                margin: 0 10px 0 0;
                                border-radius: 0;
                            }

                            svg {
                                transition: transform .2s ease-in-out,
                                    opacity .2s ease-in-out,
                                    width .2s ease-in-out,
                                    min-width .2s ease-in-out,
                                    margin-right .2s ease-in-out;
                                align-self: center;
                                opacity: 0;
                                width: 0;
                                min-width: 0;
                                transform: translateZ(0) scale(0) rotate(-45deg);
                            }

                            p {
                                color: var(--color-dark);  margin: 0;line-height: 20px;
                            }
                        }

                    }
                }

              
            }

            .form-check.active {
                border: 1px solid var(--color-c1);background: #ffe5ea;
             
            }
        }
    }
}

.search-listing {
    .search-bar {
        .form-group {
            position: relative;

            input {
                padding: 8px 120px 8px 40px;
                height: 50px;
                border-radius: 5px;
                filter: drop-shadow(-3px 3px 6px rgba(160, 160, 160, 0.660));
                position: sticky;
                top: 81px;
                box-shadow: none;
                border: none;
            }

            svg {
                position: absolute;
                top: 31%;
                left: 1%;
            }

            a {
                padding: 8px 27px;
                font-size: 17px;
                background: linear-gradient(to right, rgb(247 96 124) 0%, rgb(240, 49, 87) 100%);
                position: absolute;
                top: 4px;
                right: 4px;
                border-radius: 5px;
                text-decoration: none;
                color: var(--color-light);
            }
        }
    }

    .location-bar2 {
        display: none;

        .location-mobile {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px 20px;
            background-color: #ebebeb;
            border-radius: 20px;
            margin-bottom: 10px;

            .img-location {
                display: flex;
                justify-content: left;
                align-items: center;
                column-gap: 5px;

                img {
                    width: 20px;
                    height: 20px;
                }

                p {
                    margin: 0;
                }
            }

            a {
                color: var(--color-dark);

            }
        }
    }

    .location-sort {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .loca-part {
            display: flex;
            align-items: center;
            justify-content: left;
            column-gap: 5px;

            span {
                font-size: 17px;
                color: #f8364e;
                gap: 5px;
                line-height: 19px;
                margin: 0 5px 0 0;
                background: #f03157;
                padding: 3px;
                color: #fff;
                border-radius: 20%;
            }

            svg {
                width: 35px;
                height: 35px;
            }

            h6 {
                margin: 0;
            }
        }

        .sort-part {
            display: flex;
            align-items: center;
            justify-content: right;
            column-gap: 20px;

            .filter-mobile {
                display: none;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: right;
                column-gap: 5px;
                color: var(--color-dark);
                text-decoration: none;

                h6 {
                    margin: 0;
                }
            }
        }
    }

    .card-system {
        margin-top: 20px;

        .card {
            width: 100%;
            padding: 6px;
            border-radius: 25px;
            height: auto;
            align-items: center;


            .row.card-row {
                width: 100%;
            }



            .img-div {
                position: relative;
                padding: 0;

                &::after {
                    content: "";
                    background: url(./assets/images/top-recomended/Listing-new.png) no-repeat center 0px;
                    position: absolute;
                    top: -8%;
                    left: -13px;
                    display: block;
                    height: 90px;
                    width: 90px;
                }

                img {
                    border-radius: 15px;
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                }

                .logo-part-sec {
                    position: absolute;
                    left: 9%;
                    width: 75px;
                    bottom: 8%;
                }

                .love {
                    position: absolute;
                    top: 7%;
                    right: 7%;
                }
            }

            .body-div {
                .card-body {
                    padding: 0;
                    position: relative;
                    .card-title {
                        font-size: 1.3rem;
                        margin: 0;
                        color: var(--color-dark1);
                        margin-bottom: 5px;

                    }

                    .card-text {
                        display: flex;
                        align-items: center;
                        justify-content: left;
                        font-size: 1rem;
                        margin: 0;
                        column-gap: 5px;

                        h4 {
                            font-size: 1.6rem;
                        }

                        span {
                            img {
                                width: 25px;
                                height: 25px;
                                gap: 5px;
                                line-height: 19px;
                                margin: 0 5px 0 0;
                                background: var(--color-c1);
                                padding: 4px;
                                color: var(--color-light);
                                border-radius: 20%;
                            }
                            }
                    }

                    .col-deli-star {
                        display: flex;
                        align-items: center;
                        justify-content: left;
                        column-gap: 8px;
                        margin: 8px 0;

                        .star {
                            padding: 1px 7px;
                            background-color: var(--green);
                            border-radius: 5px;

                            p {
                                color: var(--color-light);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                -moz-column-gap: 4px;
                                column-gap: 4px;
                                margin: 0;
                                font-weight: 400;
                                align-items: baseline;
                            }
                        }

                        p {
                            margin: 0;
                            color: var(--color-c1);
                            font-weight: 600;
                        }

                        span {
                            color: var(--color-dark);
                        }
                    }

                    .percentage-colection {
                        margin-top: 8px;

                        img {
                            width: 16px;
                            border-radius: 0;
                        }

                        p {
                            display: flex;
                            align-items: center;
                            justify-content: left;
                            column-gap: 10px;
                            font-size: 15px;
                            color: var(--color-dark);
                            margin: 0;

                            span {
                                display: flex;
                                align-items: center;
                                justify-content: left;
                                column-gap: 5px;
                                color: var(--color-c1);

                                img {
                                    height: 17px;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}

// profile form page start here
.addressdtl.myform {
    .fldarea {
        Input {
            width: 100%;
            margin-bottom: 10px; border: 1px solid var(--gray1); border-radius: 10px;
        }

        select {
            width: 100%;
            padding: 10px;border: 1px solid var(--gray1); border-radius: 10px;
        }

    }

    .anchor {
        background-color: var(--green);
        color: var(--color-light);
        border: none;
        border-radius: 5px;
    }
}

// profile form page end here

// total filter-listing body end here

// menu page start here
.menu-banner {
    background-image: url(./assets/images/menu/banner.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .banner-main {
        .banner-tag {
            background-color: #ffffff5b;
            display: inline-block;
            border-radius: 20px;
            p {
                color: var(--color-light);
                padding: 5px 10px;
                margin: 0;
            }
        }
        .banner-logo-details {
            margin-top: 30px;
            padding-bottom: 60px;

            .logo-sec {
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                    box-shadow: 0 4px 6px 0 rgba(27, 35, 36, 0.02), 0 2px 12px -2px rgba(27, 35, 36, 0.08), 0 3px 6px 0 rgba(27, 35, 36, 0.06);
                }
            }
            .banner-details {
                h1 {
                    color: var(--color-light);
                }

                p {
                    color: var(--color-light);
                    margin: 0;
                }

                .star-details {
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    column-gap: 10px;
                    align-items: baseline;

                    svg {
                        fill: var(--star);
                    }
                }
            }
        }
    }
}

.menu-fulldetails {
    padding: 0px 0 200px 0;
    position: relative;
    .menu-fulldetails-row {
        row-gap: 10px;
        .menu-items {
            background-color: #fff;
            border-radius: 5px;
            height: fit-content;
            position: sticky;
            top: 10px;
            ul {
                padding: 1.3rem;
                margin: 0;
                box-shadow: 0 4px 6px 0 rgba(27, 35, 36, 0.02), 0 2px 12px -2px rgba(27, 35, 36, 0.08), 0 3px 6px 0 rgba(27, 35, 36, 0.06);
               li {
                    list-style: none;
                    padding: 10px 20px;
                    border-bottom: 1px solid #ededed;
                    border-left: 3px solid transparent;

                    &:hover {
                        border-left: 3px solid var(--color-c1);

                        // border-color: var(--color-c1);
                        a {
                            padding-left: 5px;
                            color: var(--color-c1);
                            width: 100%;
                        }
                    }

                    a {
                        transition: all 0.5s ease-in-out;
                        color: var(--color-dark);
                        text-decoration: none;
                        font-size: 1rem;

                    }
                }
            }

            .active {
                color: var(--color-c1);
            }
        }
        .menu-details-all {
            .menu-offer {

                .search-bar {
                    .form-group {
                        a {
                            padding: 8px 27px;
                            font-size: 1rem;
                            background-color: var(--color-c1);
                            color: var(--color-light);
                            position: absolute;
                            top: 4px;
                            right: 4px;
                            border-radius: 5px;
                            text-decoration: none;
                        }
                    }
                }
            }

            .menu-tabs {
                border-radius: 10px 10px 0px 0px;
                border: none;
                justify-content: space-between;

                li {
                    button {
                        background-color: transparent;
                        border-top: none;
                        border-left: none;
                        border-right: none;
                        font-size: 1rem;

                        &.active {
                            border-bottom: 3px solid var(--color-c1);

                            p {
                                color: var(--color-c1);
                            }

                            .img-div {
                                span {
                                    background-color: var(--color-dark);
                                }
                            }
                        }

                        &:hover {
                            border-bottom: 3px solid var(--color-c1);

                            p {
                                color: var(--color-c1);
                            }
                        }

                        img {
                            display: none;
                        }

                        .img-div {
                            position: relative;

                            span {
                                position: absolute;
                                top: -24%;
                                left: 58%;
                                background-color: var(--color-c1);
                                color: var(--color-light);
                                font-size: 10px;
                                padding: 3px;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 20px;
                                width: 20px;
                            }
                        }

                        p {
                            color: var(--color-dark);
                            margin: 0;
                            font-size: 1.1rem;
                        }
                    }
                }

                .crd-display {
                    display: none;
                }
            }
            p.menu-responsive{ display: none;}
            .menu-offer {
                .owl-carousel {
                    margin-top: 20px;
                    .item {
                        .same-div {
                            background-color: var(--color-light);
                            padding: 10px 15px;
                            border-radius: 8px;
                            border: 1px solid var(--line);
                            a {
                                display: inline-flex;
                                align-items: center;
                                justify-content: left;
                                column-gap: 10px;
                                text-decoration: none;

                                h6 {
                                    color: var(--color-dark);
                                    margin: 0;
                                    font-family: myfont1;
                                    font-weight: 600;
                                }

                                p {
                                    color: var(--color-dark);
                                    margin: 0;
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                    .owl-dots {
                        display: flex;
                        justify-content: center;
                        column-gap: 10px;
                        margin-top: 12px;

                        .owl-dot {
                            height: 10px;
                            width: 10px;
                            background-color: rgb(190, 190, 190);
                            border-radius: 6px;

                            &.active {
                                background-color: var(--color-c1);
                            }
                        }
                    }
                }
                .search-bar {
                    margin-top: 20px;

                    .form-group {
                        position: relative;

                        input {
                            padding: 8px 120px 8px 40px;
                            height: 50px;
                            border-radius: 5px;
                            filter: drop-shadow(-3px 3px 6px rgba(112, 112, 112, 0.25));
                            position: sticky;
                            top: 81px;
                            box-shadow: none;
                            border: none;
                        }

                        svg {
                            position: absolute;
                            top: 31%;
                            left: 2%;
                        }

                        a {
                            padding: 8px 27px;
                            font-size: 17px;
                            background: linear-gradient(to right, #a60825 0%, #f03157 100%);
                            color: var(--color-light);
                            position: absolute;
                            top: 4px;
                            right: 4px;
                            border-radius: 5px;
                            text-decoration: none;
                        }
                    }
                }
                .accordian-part {
                    width: 100%;
                    background-color: #fff;
                    border-radius: 8px;

                    .search-section {
                        border: 1px solid #ebebeb;
                        padding: 10px 10px;
                        margin-bottom: 10px;
                        .product-details-list {
                            margin-bottom: 10px;
                        }
                        h5 {
                            font-size: 1.2rem;
                            color: var(--color-c1);
                        }
                        h6 {
                            font-size: 1rem;
                            margin: 0;
                        }
                        .button-div {
                            background: var(--green);
                            position: relative;
                            padding: 5px 20px;
                            border-radius: 5px;
                            display: table;
                            margin: 0 0 0 auto;
                            a {
                                color: var(--color-light);
                            }

                            svg {
                                fill: var(--color-light);
                            }

                            p {
                                position: absolute;
                                top: -10px;
                                width: auto;
                                right: -4px;
                                text-align: center;
                                background: #000;
                                display: inline-block;
                                width: 20px;
                                height: 20px;
                                color: #fff;
                                border-radius: 50px;
                            }
                        }
                        .button-div.search-btn {
                            justify-content: center;
                        }
                        .search-top {
                            background-color: var(--color-c1);
                            color: var(--color-light);
                            padding: 10px;
                            h5 {
                                color: var(--color-light);
                                margin: 0;
                            }
                            .svg-search {
                                display: flex;
                                justify-content: right;
                            }
                        }

                        svg {
                            fill: var(--color-light);
                            width: 25px;
                            height: 25px;
                        }

                        .button-div {
                            display: flex;
                            justify-content: right;
                        }
                    }
                    .accordion-contianer {
                        width: 100%;
                        background-color: var(--color-light);
                        margin-bottom: 10px;

                        .menu-pro-cont-div {
                            padding: 0px 0px 0 0px;}

                        p {
                            line-height: 18px;}

                        .business { box-shadow: none;}
                        button {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            border: 1px solid #feadbd;
                            background-color: #ffcdd7;
                            padding: 10px;
                            border-radius: 8px;

                            p {
                                color: #ccc; }

                            &.active {
                                svg {
                                    transform: rotate(180deg);
                                }
                            }
                            span {
                                svg {
                                    width: 15px;
                                    height: 15px;
                                    -webkit-transition: -webkit-transform .8s ease-in-out;
                                    -ms-transition: -ms-transform .8s ease-in-out;
                                    transition: transform .8s ease-in-out;
                                }
                            }
                        }
                        .panel {
                            h4 {

                                color: var(--color-dark);
                                border-bottom: 1px solid var(--line);
                                text-transform: uppercase;
                                font-size: 1.1rem;
                                line-height: 27px;
                            }
                            h5 {
                                color: var(--color-dark);
                                margin-bottom: 8px;
                                text-transform: uppercase;
                            }
                            .product-details-list {
                                align-items: self-start;
                                padding: 5px 0 0px 0;
                                margin-bottom: 13px;
                                .name-div {
                                    h6 {
                                        color: var(--color-dark);
                                        margin-bottom: 5px;
                                        text-transform: capitalize;
                                        margin: 0;

                                        span {
                                            color: var(--color-c1);
                                            font-family: var(--font2);
                                            font-weight: 700;
                                        }
                                    }

                                    p {
                                        font-size: 15px;
                                    }
                                }
                                .button-div {
                                    display: flex;
                                    justify-content: end;
                                    a {
                                        padding: 5px 10px;
                                        font-size: 1rem;
                                        background-color: var(--green);
                                        color: var(--color-light);
                                        border-radius: 5px;
                                        text-decoration: none;
                                        position: relative;

                                        p {
                                            position: absolute;
                                            padding: 5px;
                                            font-size: 13px;
                                            color: var(--color-light);
                                            background-color: var(--color-c1);
                                            border-radius: 50%;
                                            height: 20px;
                                            width: 20px;
                                            top: -17%;
                                            right: -6%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            margin: 0;
                                        }
                                    }
                                }

                                .button-div-two {
                                    display: flex;
                                    align-items: center;
                                    justify-content: end;

                                    .total-button {
                                        column-gap: 10px;
                                        padding: 3px 5px;
                                        border: 1px solid var(--color-c1);
                                        background-color: var(--color-c1-low);
                                        display: inline-flex;
                                        border-radius: 5px;
                                        filter: drop-shadow(-3px 3px 6px rgba(112, 112, 112, 0.2));
                                    }

                                    a {
                                        text-decoration: none;
                                    }

                                    p {
                                        margin: 0;
                                        color: var(--color-dark);
                                        margin-top: 0px;
                                    }
                                }
                                .button-div-three {
                                    display: flex;
                                    align-items: center;
                                    justify-content: end;

                                    .button-div-all {
                                        display: flex;
                                        align-items: center;
                                        justify-content: end;
                                        column-gap: 10px;

                                        h5 {
                                            margin: 0;
                                            color: var(--color-dark);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .review-section {
                .review {
                    width: 100%;
                    padding: 15px 0px;
                    background-color: #fff;
                    border-radius: 8px;

                    p {
                        text-align: center;
                    }

                    .review-none {
                        p {
                            color: var(--color-dark);
                            text-align: center;
                        }
                    }

                    a {
                        width: 100%;
                        background-color: var(--color-c1);
                        color: var(--color-light);
                        font-size: 18px;
                        padding: 10px;
                        text-align: center;
                        text-decoration: none;
                        display: flex;
                        justify-content: center;
                        border-radius: 5px;
                        filter: drop-shadow(-3px 3px 6px rgba(112, 112, 112, 0.15));
                    }
                    .review.content1{ border-top: 1px solid var(--all);
                        border-bottom: 1px solid var(--all);
                        margin-top: 20px;
                        border-radius: 0; text-align: center; background-color: var(--all); margin-bottom: 15px;}
                }
                }
                .rating{ background-color: var(--all); padding: 10px;
                    p{ text-align: left; font-size: 1rem;}
                   
                .diff{
                    p{text-align: left;}
                }
               
            }

            

            .info-details {
                .info-section {
                    width: 100%;
                    padding: 15px 0px;
                    background-color: var(--color-light);
                    border-radius: 8px;

                    h6 {
                        color: var(--color-dark);
                        text-align: left;
                        margin-top: 10px;
                    }

                    table {
                        width: 100%;
                        background-color: azure;
                        border: 1px solid var(--modal);

                        td {
                            border-top: 1px solid var(--modal);
                            border-bottom: 1px solid var(--modal);
                            padding: 8px;
                        }

                        .days {
                            width: 40%;
                            padding-right: 20px;
                            text-align: right;
                        }

                        .timing {
                            width: 60%;
                            text-align: left;
                            text-align: left;
                        }
                    }

                    .postcode-sec {
                        ul {
                            display: flex;
                            align-items: center;
                            justify-content: left;
                            column-gap: 25px;
                            flex-wrap: wrap;
                            row-gap: 10px;
                            padding: 0;

                            li {
                                font-size: 0.875rem;
                                color: var(--color-dark);
                                list-style: none;
                                padding: 3px 20px;
                                border: 1px solid var(--modal);
                                border-radius: 5px;
                                width: 103px;
                            }
                        }
                    }
                }
            }

            .book-section {
                width: 100%;
                padding: 15px 20px;
                background-color: #fff;
                border-radius: 8px;
                box-shadow: 0 4px 6px 0 rgba(27, 35, 36, 0.02), 0 2px 12px -2px rgba(27, 35, 36, 0.08), 0 3px 6px 0 rgba(27, 35, 36, 0.06);

                .error {
                    color: var(--btn-red)
                }

                h6 {
                    color: var(--color-dark);
                    text-align: left;
                    margin-top: 10px;
                }

                p {
                    color: var(--color-dark);
                    text-align: left;
                }

                label {
                    color: var(--color-dark);
                    font-size: 1rem;

                    span {
                        color: var(--color-c1);
                    }
                }
                .radio-all-button {
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    flex-wrap: wrap;
                    column-gap: 10px;
                    row-gap: 10px;
                    box-shadow: none;

                    .form-check-input {
                        position: relative;
                        bottom: 3px;
                    }
                    .form-check-input {
                        &:checked {
                            background-color: var(--color-c1);
                            border-color: var(--color-c1);
                            position: relative;
                            bottom: 3px;
                        }
                    }
                }
                .owl-carousel {
                    .item {
                        a {
                            padding: 5px 25px;
                            background-color: var(--green-low);
                            color: var(--green);
                            font-size: 1rem;
                            border: 1px solid var(--green);
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
        .menu-cash-total {
            background-color: var(--color-light);
            border-radius: 5px;
            height: fit-content;
            position: sticky;
            top: 10px;

            .note-div {
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 10px;
                padding: 10px;
                color: var(--star);
                background-color: var(--back-yellow);
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

                p {
                    margin: 0;

                }

                svg {
                    fill: var(--star);
                    width: 18px;
                    line-height: 18px;
                }
            }
            .allergies {
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: left;
                column-gap: 10px;

                svg {
                    fill: var(--color-dark);
                }

                p {

                    margin: 0;
                    line-height: 18px;
                }
            }

            .delivery-collection {
                padding: 15px;
                box-shadow: 0 4px 6px 0 rgba(27, 35, 36, 0.02), 0 2px 12px -2px rgba(27, 35, 36, 0.08), 0 3px 6px 0 rgba(27, 35, 36, 0.06);

                .switch-toggle {
                    --offset: 2px;
                    --radius: 4px;
                    background: rgba(227, 229, 232, 0.5);
                    border-radius: var(--radius);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    column-gap: 10px;
                    padding: 8px;

                    .button {
                        margin: 0;
                        width: 50%;
                        height: 54px;
                        position: relative;

                        label,
                        input {
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background-color: var(--color-light);
                            margin-bottom: 0;
                            padding-top: 5px;
                            border: none;
                            margin: 0;
                            &:disabled{ background-color:var(--gray1);}

                        }
                        input{
                            &:disabled+label{
                                background: #e0e0e0;
                                
                            }
                        }

                        input[type=radio] {
                            &:checked+label {
                                background: var(--color-c1);
                                border-radius: 4px;
                                color: var(--color-light);
                                h6 {
                                    color: var(--color-light);
                                }

                                p {
                                    color: var(--color-light);
                                }

                                svg {

                                    fill: var(--color-light);

                                }
                            }

                        }

                        #business_collection_radio_delivery:disabled+label {
                            background: #dddddd;
                            cursor: auto;
                        }
                        .total-div {
                            display: flex;
                            justify-content: center;
                            line-height: 15px;
                            margin-top: 6px;
                            .right-div {
                                h6 {
                                    font-size: 1rem;
                                    margin: 0;
                                    text-align: left;
                                    color: var(--color-dark);
                                }
                            }
                            svg {
                                height: 25px;
                                width: 25px;
                                fill: var(--color-dark);
                                margin-top: 8px;
                                margin-right: 8px;
                            }

                            .switch-toggle .button input[type=radio]:checked+label .total-div .left-div svg {
                                fill: var(--color-light);
                            }
                        }

                    }
                }

                .switch-toggle.left {
                    display: block;

                    .button {
                        width: 100%;
                        margin-bottom: 10px;

                        .total-div {
                            justify-content: start;

                            svg {
                                margin: 0;
                            }
                        }
                    }
                }

                .tab-slider--body {
                    .second-content {
                        .eliment-content {
                            width: 100%;
                            font-size: 0.438rem;
                            text-transform: uppercase;

                            .item-name {
                                width: 30%;
                                text-align: left;
                                font-size: 1rem;
                            }

                            .item-middle {
                                width: 50%;
                            }

                            .item-price {
                                width: 20%;
                                text-align: right;
                                font-size: 1rem;
                            }
                        }

                        .item-product-details {
                            width: 100%;
                            padding-bottom: 15px;
                            margin-bottom: 10px;
                            border-bottom: 1px solid var(--modal);

                            .plus-minus {
                                width: 20%;
                                display: flex;
                                align-items: center;
                                justify-content: left;
                                column-gap: 8px;
                                .plus-minus-h5-wid{
                                    width: 25px ;
                                    text-align: center;
                                    h5 {
                                        margin-top: 3px;
                                        margin-bottom: 0;
                                        color: var(--green);
                                        width: 25px ;
                                    }
                                }
                            }

                            .item-name {
                                width: 60%;

                                h5 {
                                    text-align: left;
                                    text-transform: uppercase;
                                    margin: 0;
                                    font-size: 1rem;
                                }

                                p {
                                    margin: 0;
                                }

                                h6 {
                                    padding-left: 10px;
                                    margin-bottom: 0;
                                    margin: 0;
                                }
                            }

                            .price-tag {
                                width: 20%;
                                text-align: right;
                                vertical-align: top;
                            }
                        }

                        .free-dish-top {
                            width: 100%;
                            margin-bottom: 20px;

                            .free-title {
                                margin-bottom: 15px;

                                h6 {
                                    width: 100%;
                                    background-color: var(--button-back);
                                    padding: 10px;
                                    border: 1px solid var(--green);
                                    border-radius: 5px;
                                    color: var(--green);
                                }
                            }
                        }

                        .free-total-div {
                            background-color: #f3f3f3;
                            padding: 20px 5px;
                            border-radius: 5px;
                            margin-bottom: 10px;

                            .free-dish-items {
                                width: 100%;
                                position: relative;

                                .items {
                                    padding: 3px 3px 3px 10px;
                                    width: 70%;

                                    span {
                                        color: var(--green);
                                    }
                                }

                                .price {
                                    width: 30%;
                                    padding: 3px 10px 3px 3px;
                                    text-align: right;
                                    color: var(--color-c1);
                                    font-family: var(--font1);
                                    font-weight: 600;
                                }

                                .edit-btn {
                                    position: absolute;
                                    top: -100%;
                                    right: 0%;
                                    height: 25px;
                                    width: 25px;

                                    a {
                                        background-color: #fff;
                                        padding: 4px;
                                        height: 22px;
                                        width: 26px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        border-radius: 3px;
                                        position: absolute;
                                        top: 32px;
                                        
   
                                    }
                                }
                            }
                        }

                        .Sub-total-sec {
                            width: 100%;
                            margin-bottom: 10px;
                            border-bottom: 1px solid var(--modal);
                            margin-top: 10px;

                            .name {
                                width: 70%;
                                text-align: left;
                                font-family: var(--font1);
                                font-weight: 500;
                                font-family: "Outfit", sans-serif;

                            }

                            .price {
                                width: 30%;
                                text-align: right;
                            }
                        }

                        .total-price {
                            width: 100%;
                            font-size: 18px;
                            margin-top: 5px;

                            .total-p {
                                width: 70%;
                                text-align: left;
                            }

                            .price {
                                width: 30%;
                                text-align: right;
                            }
                        }

                        .input-sec {
                            margin-top: 15px;
                            span.mybutton{ color: var(--btn-red);
                                font-weight: 700;
                                border: 1px dashed var(--btn-red);
                                padding: 5px;
                                display: inline-block;
                                float: right;
                                margin-bottom: 10px;}

                            svg {
                                margin: -4px 0 0 0;
                            }

                            form {
                                position: relative; width: 100%;

                                input {
                                    box-shadow: none;
                                    padding: 10px 80px 10px 10px;
                                    font-weight: 500;
                                    text-transform: uppercase;
                                }

                                a {
                                    color: var(--color-c1);
                                    font-size: 15px;
                                    position: absolute;
                                    right: 1%;
                                    top: 20%;
                                    text-decoration: none;
                                    font-weight: 600;
                                }
                            }
                        }
                        .error {
                            color: var(--btn-red); margin-bottom: 10px;
                        }
                        .anchor-tag {
                            a {
                                width: 100%;
                                background-color: var(--color-c1);
                                color: var(--color-light);
                                font-size: 18px;
                                padding: 10px;
                                text-align: center;
                                text-decoration: none;
                                display: flex;
                                justify-content: center;
                                border-radius: 5px;
                                filter: drop-shadow(-3px 3px 6px rgba(112, 112, 112, 0.15));
                            }

                            button {
                                width: 100%;
                                background-color: var(--color-c1);
                                color: var(--color-light);
                                font-size: 18px;
                                padding: 10px;
                                text-align: center;
                                text-decoration: none;
                                display: flex;
                                justify-content: center;
                                border-radius: 5px;
                                filter: drop-shadow(-3px 3px 6px rgba(112, 112, 112, 0.15));
                                border: none;
                            }

                            button:disabled {

                                background-color: var(--color-c1-low);
                                color: var(--color-dark);

                            }
                        }

                        .booking-side-panel {
                            width: 100%;
                            margin-top: 10px;

                            .book-panel-left {
                                width: 30%;
                                margin-bottom: 10px;
                                border-bottom: 1px solid var(--modal);

                                h6 {
                                    color: var(--color-dark);
                                    text-align: left;
                                    padding-top: 10px;
                                    padding-bottom: 10px;
                                    margin: 0;
                                    padding-right: 5px;
                                }

                                p {
                                    margin: 0;
                                    padding-right: 5px;
                                    padding-top: 10px;
                                    padding-bottom: 10px;

                                    span {
                                        color: var(--color-dark);
                                        margin: 0;
                                        padding-right: 5px;
                                    }
                                }

                                span {
                                    color: var(--color-dark);
                                    margin: 0;
                                    padding-right: 5px;
                                }
                            }

                            .book-panel-right {
                                width: 70%;
                                margin-bottom: 10px;
                                border-bottom: 1px solid var(--modal);

                                h6 {
                                    color: var(--color-dark);
                                    text-align: right;
                                    margin: 0;
                                    padding-bottom: 5px;
                                    padding-left: 5px;
                                    padding-top: 10px;
                                    padding-bottom: 10px;
                                }

                                p {
                                    color: var(--color-dark);
                                    text-align: right;
                                    margin: 0;
                                    padding-left: 5px;
                                    padding-top: 10px;
                                    padding-bottom: 10px;
                                }
                            }

                            .full-wid {
                                width: 100%;
                                margin-bottom: 10px;
                                border-bottom: 1px solid var(--modal);
                                column-gap: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// menu page end here
// edit modal start here
.free-dish-section-body {
    padding: 0;

    .free-dish-section {
        h4 {

            text-align: center;
            padding: 8px;
            color: var(--color-c1);
            margin-bottom: 0;
        }

        .item-dish {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: var(--body);
            padding: 8px;
            border-top: 1px solid var(--color-dark);
            border-bottom: 1px solid var(--color-dark);
            margin-bottom: 0px;

            h5 {
                color: var(--color-dark);
                margin: 0;
            }
        }

        form {
            padding: 15px;

            .form-check {
                display: flex;
                align-items: center;
                justify-content: left;
                column-gap: 10px;

                input {
                    float: none;
                    margin: 0;
                    position: relative;
                    background-color: var(--color-light);
                    border-color: #b9b9b9;
                    box-shadow: none;
                }
            }
        }
    }
}

// edit modal end here

// delivery collection model start here
.del-collection-modal {
    .anchor-div {
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 25px;
            background-color: var(--color-light);
            color: var(--color-c1);
            border: 1px solid var(--color-c1);
            border-radius: 5px;
            text-decoration: none;

            &:hover {
                background-color: var(--green);
                color: var(--color-light);
                border-color: var(--green);
            }

            &.active {
                background-color: var(--green);
                color: var(--color-light);
                border-color: var(--green);
            }
        }
    }
}

// delivery collection model end here

// login for go to checkout modal start here
.go-to-checkout-modal {
    .left-modal {
        h5 {
            color: var(--color-dark);
            text-align: center;
            padding-bottom: 5px;
            margin-bottom: 10px;
            border-bottom: 1px solid var(--modal);
        }

        form {
            input {
                height: 2.813rem;
                box-shadow: none;
            }

            button {
                width: 100%;
                background-color: var(--color-light);
                color: var(--color-c1);
                border: 1px solid var(--color-c1);
                font-size: 1.063rem;

                &:hover {
                    background-color: var(--green);
                    color: var(--color-light);
                    border-color: var(--green);
                }
            }

            a {
                font-size: 0.938rem;
                text-align: center;
                width: 100%;
                color: var(--color-dark);
            }
        }
    }

    .right-modal {
        h5 {
            color: var(--color-dark);
            text-align: center;
            padding-bottom: 5px;
            margin-bottom: 10px;
            border-bottom: 1px solid var(--modal);
        }

        .same-div {
            h6 {
                color: var(--color-dark);
                margin: 0;
                padding-top: 10px;
            }

            p {
                color: var(--color-dark);
                margin-bottom: 15px;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 25px;
                background-color: var(--color-c1);
                color: var(--color-light);
                border: 1px solid var(--color-c1);
                border-radius: 5px;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    background-color: var(--green);
                    color: var(--color-light);
                    border-color: var(--green);
                }
            }
        }

        .same-div1 {
            padding-bottom: 10px;
            border-bottom: 1px solid var(--modal);
        }

        h4 {
            color: var(--color-dark);
            padding: 10px;
            text-align: center;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                width: 42%;
                height: 1px;
                background-color: var(--line);
                top: 50%;
                left: 0;
                z-index: 0;
            }

            &::before {
                content: "";
                position: absolute;
                width: 42%;
                height: 1px;
                background-color: var(--line);
                top: 50%;
                right: 0;
                z-index: 0;
            }
        }

        .social-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 10px;
            background-color: #b1b1b12b;
            padding: 10px;
            margin-top: 20px;

            h6 {
                color: var(--color-c1);
                margin: 0;
                font-size: 1.2rem;
            }

            a {
                padding: 5px 15px;
                background-color: var(--color-light);
                font-size: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 8px;
                text-decoration: none;
                border: 1px solid var(--color-dark);
                border-radius: 5px;
                color: var(--color-dark);

                img {
                    height: 20px;
                    width: 20px;
                }
            }
        }

    }
}

// login for go to checkout modal end here

// forget your password start here
.forget-pass-modal {
    filter: drop-shadow(-3px 3px 6px rgba(112, 112, 112, 0.8));
}

// forget your password end here

// create account go to checkout modal start here
.ca-checkout-modal {
    form {
        label {
            font-size: 1.063rem;
            color: var(--color-dark);

            span {
                color: var(--color-c1);
            }
        }
    }
}

// create account go to checkout modal end here

// checkout page start here
.checkout-all {
    .checkout {
        padding-bottom: 200px;

        .business-new-drop-down {
            margin-top: 0;
        }

        .checkout.bottom {
            margin: 0;
            padding: 0;

            .switch-toggle {
                display: block;
                background-color: var(--color-light);
                border: 1px solid var(--checkout);
            }

            .button {
                width: 100%;

                .active {
                    background-color: var(--color-c1);

                    .total-div {
                        display: flex;
                        justify-content: start;

                        svg {
                            fill: var(--color-light);
                        }

                        ;

                    }

                }

                label.btn.btn-default.active h6 {
                    color: var(--color-light) !important;
                    margin: 0 0px 0 10px;
                }



                label.btn.btn-default h6 {
                    margin: 0 0px 0 10px;
                }

                .total-div {
                    display: flex;
                    justify-content: start;

                    svg {
                        fill: var(--checkout);
                    }

                    ;

                }

            }

            button.btn.order-now {
                background-color: var(--green)
            }

            button.btn.order-now:hover {
                border: 1px solid var(--green);
                color: var(--color-light);
                border: none;
            }
        }

        .checkout-bg {
            background-color: var(--bg-color, #FCFCFC);
            padding: 20px;
            border-radius: 5px;

            table {
                width: 100%;
            }

            .price {
                text-align: right;
            }

            table.total-price td {
                font-size: 1.1rem;
                border-top: 1px solid rgb(191, 191, 191);
            }
        }

        .switch-toggle {
            --offset: 2px;
            --radius: 4px;
            background: rgba(227, 229, 232, 0.5);
            border-radius: var(--radius);
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 10px;
            padding: 8px;

            .checkout .bottom {
                margin: 0;
            }

            .button {
                margin: 0;
                width: 50%;
                height: 54px;
                position: relative;

                label,
                input {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: var(--color-light);
                    margin-bottom: 0; padding-left: 10px;
                }

                input[type=radio] {
                    &:checked+label {
                        background: var(--color-c1);
                        border-radius: 4px;
                        color: var(--color-light);

                        h6 {
                            color: var(--color-light);
                            font-size: 1rem;
                        }

                        p {
                            color: var(--color-light);
                        }

                        svg {

                            fill: var(--color-light);

                        }
                    }

                }

                .total-div {
                    display: flex;
                    justify-content: center;
                    -moz-column-gap: 8px;
                    column-gap: 8px;
                    line-height: 15px;


                    .right-div {
                        h6 {
                            font-size: 1rem;
                            margin: 0;
                            text-align: left;
                            color: var(--color-dark);
                            font-weight: 600;
                        }



                    }

                    svg {
                        height: 25px;
                        width: 25px;
                        fill: var(--color-dark);
                    }

                    .switch-toggle .button input[type=radio]:checked+label .total-div .left-div svg {
                        fill: var(--color-light);
                    }


                }



            }
        }

        .dropdown {


            display: inline-block;
            position: relative;
            width: 100%;

            .dd-button {
                display: inline-block;
                border: 1px solid gray;
                border-radius: 4px;
                background-color: #ffffff;
                cursor: pointer;
                padding: 8px 10px;
                border: 1px solid rgb(191, 191, 191);
                border-radius: 0.375rem;
                white-space: nowrap;
                width: 100%;
                display: flex;
                margin-top: 10px;
            }

            .dd-button:after {
                content: '';
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid black;
            }

            .dd-input {
                display: none;
            }

            .dd-menu {
                position: absolute;
                top: 100%;
                border: 1px solid #ccc;
                border-radius: 4px;
                padding: 0;
                margin: 2px 0 0 0;
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
                background-color: #ffffff;
                list-style-type: none;
                width: 100%;
                z-index: 9;
            }

            .dd-input+.dd-menu {
                display: none;
            }

            .dd-input:checked+.dd-menu {
                display: block;
            }

            .dd-menu li {
                padding: 10px 20px;
                cursor: pointer;
                white-space: nowrap;
            }

            .dd-menu li:hover {
                background-color: #f6f6f6;
            }

            .dd-menu li a {
                display: block;
                margin: -10px -20px;
                padding: 10px 20px;
            }

            .dd-menu li.divider {
                padding: 0;
                border-bottom: 1px solid #cccccc;
            }

            svg {
                width: 20px;
                height: 20px;
                fill: var(--checkout);
            }

            .text {
                line-height: 16px;
                margin-left: 10px;

                span {
                    font-size: 0.7rem;
                }

            }

        }


        .checkout-aside-1 {
            h6 {
                margin: 0;
            }

            .btn {
                margin: 0;
                width: 100%;
                border: none;

                &:hover {
                    color: var(--color-light);
                }
            }

            .btn:hover {
                border: none;
                color: var(--color-c1);
                border: 1px solid var(--color-c1);
            }

            span {
                // margin-bottom: 10px;
            }

            .checkout-aside-1 {


                position: relative;

                .input-area {
                    padding: 7px 18px;
                    line-height: 1;
                    border: 1px solid transparent;
                    border-radius: 0.5rem;
                    color: rgb(0, 0, 0);
                    background-color: rgb(229, 229, 229);
                    outline: none;
                    margin-top: 10px;


                    label {
                        font-size: 12px;
                        color: rgb(128, 128, 128);
                        padding-top: 10px;
                        line-height: 10px;
                    }

                    input {
                        width: 100%;
                        padding: 0;
                        border: none;
                        background: none;
                    }

                    textarea {
                        width: 100%;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                        line-height: 1;
                        padding: 0;
                        margin: 0;
                        border: 1px solid transparent;
                        border-radius: 0.5rem;
                        color: rgb(0, 0, 0);
                        background-color: rgb(229, 229, 229);
                        outline: 0px;
                        resize: none;

                        span {
                            float: right;
                            position: absolute;
                            bottom: 10px;
                        }
                    }

                    .btn {
                        margin: 0;
                    }

                    .form-check {
                        input {
                            padding: 0;
                        }

                    }


                }
            }

        }
    }

    h3 {
        color: var(--color-c1);
        text-align: left;
        margin-bottom: 0;
        font-size: 1.4rem;
        margin-bottom: 10px;
    }

    .button-sec {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: left;
        text-align: center;



        .back {
            margin-top: 30px;

            a {
                display: flex;
                align-items: center;
                justify-content: left;
                column-gap: 5px;
                color: var(--color-light);
                text-decoration: none;

                svg {
                    fill: var(--color-c1);
                }

                span {
                    color: var(--color-c1);

                }

            }
        }
    }

    .right-panel {
        .your-order-sec {


            .order-div {
                table {
                    width: 100%;
                }

                td {
                    font-weight: 600;
                }

                td:last-child {
                    text-align: right;
                }

                td:first-child {
                    color: var(--color-c1);
                }

                input {
                    width: 100%;
                    margin-bottom: 10px;
                }

            }


            h4 {
                color: var(--color-dark);
                margin-bottom: 0.938rem;
            }

            .order-div {
                .order {
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;

                    p {
                        margin: 0;
                        color: var(--color-dark);
                        text-transform: uppercase;

                        span {
                            color: var(--color-c1);
                        }
                    }

                    .extra {
                        font-size: 0.875rem;
                        color: var(--color-dark);
                        text-transform: capitalize;

                        span {
                            padding-left: 10px;
                            color: var(--color-dark);
                        }
                    }

                    .right {
                        p {}
                    }
                }

                form {
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }

            .freebies-div {
                display: flex;
                align-items: baseline;
                justify-content: space-between;
                padding-bottom: 10px;
                margin-bottom: 10px;
                border-bottom: 1px solid var(--modal);

                p {
                    text-transform: uppercase;
                    color: var(--color-dark);
                    margin: 0;
                }

                .left {
                    p {
                        span {
                            color: var(--green);
                            font-weight: 700;
                        }
                    }
                }

                .right {
                    p {
                        color: var(--color-c1);
                        text-decoration: line-through;
                        font-weight: 700;
                    }
                }
            }

            .subtotal {
                padding-bottom: 10px;
                margin-bottom: 10px;
                border-bottom: 1px solid var(--modal);
                width: 100%;

                .left {
                    text-align: left;
                    color: var(--color-dark);
                    width: 60%;
                }

                .right {
                    text-align: right;
                    font-size: 0.938rem;
                    color: var(--color-dark);
                    width: 40%;
                }
            }

            .total {
                padding-top: 10px;
                width: 100%;

                .left {
                    width: 60%;
                    text-align: left;
                    font-size: 1.063rem;
                    color: var(--color-dark);
                }

                .right {
                    width: 40%;
                    text-align: right;
                    font-size: 1.063rem;
                    color: var(--color-dark);
                }
            }
        }

        .freebies-discount {


            table {
                width: 100%;

                .left {
                    width: 60%;
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    column-gap: 10px;

                    p {
                        color: var(--green);
                        margin: 0;
                    }
                }

                .right {
                    width: 40%;

                    p {
                        color: var(--green);
                        text-align: right;
                        margin: 0;
                    }
                }
            }
        }

        .send-me-offer {
            background-color: var(--color-light);
            filter: drop-shadow(-3px 3px 6px rgba(112, 112, 112, 0.3));
            padding: 15px 10px;
            border-radius: 5px;
            margin-bottom: 20px;


            .form-check {
                padding-left: 0;
                display: flex;
                align-items: center;
                justify-content: left;
                column-gap: 10px;

                .form-check-input {
                    position: relative;
                    margin: 0;
                    background-color: var(--color-c1);
                    box-shadow: none;
                    border-color: var(--color-c1);
                    background-color: #fff;

                    &:checked {
                        background-color: var(--color-c1);
                        border-color: var(--color-c1);
                        box-shadow: none;
                    }




                    label {
                        font-size: 0.938rem;
                        color: var(--color-dark);
                        border: 1px solid;
                    }
                }
            }
        }

        .pay-method {
            padding: 30px;
            border: 1px solid #ebebeb;
            border-radius: 20px;
            border-bottom: 10px solid var(--color-c1);
            box-shadow: 0 4px 6px 0 rgba(27, 35, 36, 0.02), 0 2px 12px -2px rgba(27, 35, 36, 0.08), 0 3px 6px 0 rgba(27, 35, 36, 0.06);
            background-color: var(--color-light);

            h5 {
                color: var(--color-dark);
                margin-bottom: 15px;
            }

            .pay-order {

                .form-check {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 6px;
                    border: 1px solid var(--modal);
                    border-radius: 5px;

                    .left {
                        display: flex;
                        align-items: center;
                        justify-content: left;
                        column-gap: 10px;

                        .form-check-input {
                            position: relative;
                            margin: 0;

                            &:checked {
                                background-color: var(--color-dark);
                                border-color: var(--color-dark);
                                box-shadow: none;
                            }
                        }

                        label {
                            font-size: 1rem;
                            color: var(--color-dark);
                        }
                    }

                    .right {
                        svg {
                            fill: var(--color-light);
                        }
                    }

                    &.active {
                        background-color: var(--color-c1);

                        .left {
                            label {
                                color: var(--color-light);
                            }
                        }
                    }
                }
            }

            .pick-time-sec {
                h5 {
                    color: var(--color-dark);
                    margin-bottom: 15px;
                }

                .button-div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    row-gap: 10px;

                    a {
                        width: 30%;
                        background-color: var(--color-light);
                        color: var(--color-c1);
                        border: 1px solid var(--color-c1);
                        border-radius: 5px;
                        font-size: 1rem;
                        padding: 3px 10px;
                        text-align: center;
                        text-decoration: none;

                        &.active {
                            background-color: var(--green);
                            color: var(--color-light);
                            border: 1px solid var(--green);
                        }

                        &:hover {
                            background-color: var(--green);
                            color: var(--color-light);
                            border: 1px solid var(--green);
                        }
                    }
                }

                .show-some-time {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 15px;
                    padding-bottom: 10px;
                    margin-bottom: 15px;
                    border-bottom: 1px solid var(--modal);

                    a {
                        background-color: var(--color-c1);
                        color: var(--color-light);
                        border: 1px solid var(--color-c1);
                        border-radius: 5px;
                        font-size: 1rem;
                        padding: 3px 10px;
                        text-align: center;
                        text-decoration: none;
                    }
                }

            }

        }

        .button-sec {
            display: flex;
            align-items: center;
            justify-content: center;

            .order-now {
                background-color: var(--green);
                color: var(--color-light);
                padding: 11px 20px;
                border-radius: 5px;
                text-decoration: none;
                width: 100%;
                text-align: center;
                border: none;
            }
        }
    }

    .left-panel {

        .time-part-selection {


            h5 {
                color: var(--color-dark);
                margin-bottom: 10px;
            }

            p {
                a {
                    text-decoration: none;
                    color: var(--color-c1);
                }
            }

            form {
                label {
                    font-size: 1.063rem;
                    color: var(--color-dark);
                    margin: 0;
                    cursor: pointer;
                }

                input {
                    padding: 8px;
                    bottom: -3px;
                }

                p {
                    color: var(--color-dark);
                }
            }


            .address-form-add {
                .address-form {


                    .form-check {
                        position: relative;
                        display: flex;
                        align-items: baseline;
                        justify-content: left;
                        column-gap: 10px;
                        padding: 8px 10px;
                        margin-bottom: 8px;
                        border: 1px solid var(--all);
    height: 190px;

                        border-radius: 5px;


                        .svg-sidebar {
                            text-align: center;
                            width: 100%;

                            svg {
                                width: 50px;
                                height: 50px;
                                margin: 40px 0 35px 0;
                            }
                        }

                        .form-check-input {
                            position: relative;
                            margin: 0;

                            &:checked {
                                background-color: var(--color-c1);
                                border-color: var(--color-c1);
                                box-shadow: none;
                                cursor: pointer;
                            }
                        }

                        .form-check-label {
                            h5 {
                                color: var(--color-c1);
                            }

                            p {
                                color: var(--color-dark);
                                margin: 0;
                            }
                        }

                        .edit {
                            svg {
                                height: 19px;
                                width: 19px;
                                fill: var(--color-dark);
                                position: absolute;
                                top: 5%;
                                right: 4%;

                            }
                        }
                    }

                    .activeAddr {
                        border: 1px solid var(--color-c1);
                        height: 190px;
                    }

                    .inactiveAddr {
                        border: 1px solid var(--all);
                        height: 190px;
                    }
                }

                .address-div {
                    margin: 10px 0;

                    .address {
                        display: table;
                        margin: 0 auto;
                        padding: 8px 25px;
                        background-color: var(--color-dark);
                        color: var(--color-light);
                        text-decoration: none;
                        border-radius: 5px;
                        font-size: 1.063rem;
                        text-align: center;
                    }
                }

            }

            .address-realted {
                h5 {
                    font-size: 1.125rem;
                    color: var(--color-c1);
                    margin-bottom: 5px;
                }

                p {
                    color: var(--color-dark);
                    margin: 0;
                }
            }
        }

    }

}

// checkout page end here

// successfull page start here
.Successfull-page {
    padding: 30px 0 150px 0;

    .checkmark {
        position: relative;
        padding: 30px;
        animation: checkmark 5m var(--curve) both;
        display: flex;
        justify-content: center;

        &__check {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 10;
            transform: translate3d(-50%, -50%, 0);
            fill: var(--color-light);
        }

        &__background {
            fill: var(--green);
            animation: rotate 35s linear both infinite;
        }

    }

    .star {
        position: absolute;
        animation: grow 3s infinite;
        fill: var(--green);
        opacity: 0;

        &:nth-child(1) {
            @include size(12px);
            left: 44%;
            top: 16px;
        }

        &:nth-child(2) {
            @include size(18px);
            left: 56%;
            top: 84px;
        }

        &:nth-child(3) {
            @include size(10px);
            left: 45%;
            top: 157px;
        }

        &:nth-child(4) {
            @include size(20px);
            left: 53%;
            top: -5px;
        }

        &:nth-child(5) {
            @include size(14px);
            left: 53%;
            top: 162px;
        }

        &:nth-child(6) {
            @include size(10px);
            left: 48%;
            top: 16px;
        }

        @for $i from 1 through 6 {
            &:nth-child(#{$i}) {
                animation-delay: $i*(3.0s/2);
            }
        }
    }

    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes grow {

        0%,
        100% {
            transform: scale(0);
            opacity: 0;
        }

        25% {
            transform: scale(0.5);
            opacity: 0.8;
        }

        50% {
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes checkmark {

        0%,
        100% {
            opacity: 0;
            transform: scale(0);
        }

        10%,
        50%,
        90% {
            opacity: 1;
            transform: scale(1);
        }
    }

    .order-sucess-msg {
        h3 {
            color: var(--green);
            text-transform: uppercase;
            text-align: center;
            margin-top: 15px;
            margin-bottom: 0;
        }

        p {
            color: var(--color-c1);
            text-transform: capitalize;
            text-align: center;
        }
    }

    .order-img-details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--color-light);
        padding: 15px 10px;
        border-radius: 5px;
        filter: drop-shadow(-3px 3px 6px rgba(112, 112, 112, 0.3));

        .image-order {
            display: flex;
            align-items: center;
            justify-content: left;
            column-gap: 10px;
            row-gap: 10px;

            .image-div {
                img {
                    width: 120px;
                    height: 120px;
                }
            }

            .img-details-odr {
                h3 {
                    color: var(--color-dark);
                    text-transform: capitalize;
                    text-align: left;
                    margin-top: 15px;
                    margin-bottom: 0;
                }

                .location-div {
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    column-gap: 5px;

                    img {
                        width: 18px;
                        height: 18px;
                    }

                    p {
                        color: var(--color-dark);
                        margin: 0;
                    }
                }

                .start-div {
                    svg {
                        fill: #ff900e;
                    }
                }
            }

        }

        .order-id-details {
            .order-id {
                h6 {
                    color: var(--color-c1);
                    margin: 0;
                    text-align: right;
                }

                p {
                    color: var(--color-dark);
                    margin-bottom: 15px;
                    text-align: right;
                    margin: 0;
                }

                .delivery {
                    display: flex;
                    align-items: center;
                    justify-content: right;
                    column-gap: 5px;

                    img {
                        height: 20px;
                        width: 20px;
                    }
                }

                .collection {
                    display: flex;
                    align-items: center;
                    justify-content: right;
                    column-gap: 5px;

                    img {
                        height: 20px;
                        width: 20px;
                    }
                }
            }

            .order-status {
                h6 {
                    color: var(--color-c1);
                    margin: 0;
                    text-align: right;
                }

                p {
                    color: var(--color-dark);
                    margin-bottom: 15px;
                    text-align: right;

                }
            }
        }
    }

    .order-summery {
        padding: 10px 0;

        h3 {
            color: var(--color-dark);
            text-transform: capitalize;
            text-align: center;
            margin-bottom: 0;
        }

        p {
            color: var(--color-c1);
            text-transform: capitalize;
            text-align: center;
        }
    }

    .your-order-sec {
        background-color: var(--color-light);
        filter: drop-shadow(-3px 3px 6px rgba(112, 112, 112, 0.3));
        padding: 15px 10px;
        border-radius: 5px;
        margin-bottom: 20px;

        h4 {
            color: var(--color-dark);
            margin-bottom: 15px;
        }

        .order-div {
            .order {
                display: flex;
                align-items: baseline;
                justify-content: space-between;

                p {
                    margin: 0;
                    color: var(--color-dark);
                    text-transform: uppercase;

                    span {
                        color: var(--color-c1);
                    }
                }

                .extra {
                    font-size: 0.875rem;
                    color: var(--color-dark);
                    text-transform: capitalize;

                    span {
                        padding-left: 10px;
                        color: var(--color-dark);
                    }
                }

                .right {
                    p {}
                }
            }

            form {
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }

        .freebies-div {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid var(--modal);

            p {
                text-transform: uppercase;
                color: var(--color-dark);
                margin: 0;
            }

            .left {
                p {
                    span {
                        color: var(--green);
                    }
                }
            }

            .right {
                p {
                    color: var(--color-c1);
                    text-decoration: line-through;
                }
            }
        }

        .subtotal {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid var(--modal);
            width: 100%;

            .left {
                text-align: left;
                font-size: 0.813rem;
                color: var(--color-dark);
                width: 60%;
            }

            .right {
                text-align: right;
                font-size: 0.938rem;
                color: var(--color-dark);
                width: 40%;
            }
        }

        .total {
            padding-top: 10px;
            width: 100%;

            .left {
                width: 60%;
                text-align: left;
                font-size: 1.063rem;
                color: var(--color-dark);
            }

            .right {
                width: 40%;
                text-align: right;
                font-size: 1.063rem;
                color: var(--color-dark);
            }
        }
    }
}

// business page lightbox start
.modal {
    backdrop-filter: blur(5px);

}

.modal-dialog {

    .modal-content {
        .modal-header {
            background-color: var(--color-c1);
            justify-content: space-between;
            border-radius: 5px 5px 0 0;

            .modal-title {
                color: var(--color-light);
            }

            button.close {
                border: 2px solid #fff !important;
                background: transparent;
                width: 37px;
                height: 37px;
                border-radius: 50%;
                // span{font-size: 2rem;
                //     margin: -26px 0 0 0;
                //     position: absolute;
                //     width: 100;
                //     color: #fff;
                //     right: 26px;}
               span{color: var(--color-light);
                font-size: 1.8rem;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 45px;
                font-weight: 100;}

            }

        }

        button.btn.close.btn-secondary {
            background-color: var(--color-light);
            color: red;
            border: 1px solid;
        }

        button.btn.save.btn-primary {
            background-color: var(--green);
            border: none;
            padding: 7px 15px;

        }

        .btn:hover {
            color: #fff;
        }
    }
}

// business page lightbox end

// successfull page end here
.modal {
    backdrop-filter: blur(5px);

}

.modal-filter {
    .modal-dialog {
        .modal-content {
            .modal-header {
                background-color: var(--color-c1);

                h1 {
                    color: #ffffff;
                    font-size: 1.125rem;
                    font-weight: 600;
                    text-transform: capitalize;
                    display: table;
                    margin: 0 auto;
                }

                button {

                    background-image: url('./assets/images/modal1-navigation/x.png');
                    position: absolute;
                    right: 3%;
                    box-shadow: none;
                    margin: 0;
                    padding: 0;
                    opacity: 1;
                    border: 2px solid #fff;
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                }
            }

            .modal-body {
                max-height: 500px;
                overflow-y: scroll;

                .modal-body-img {
                    height: 334px;
                }

                &::-webkit-scrollbar {
                    display: none;
                }

                h3 {
                    text-align: center;
                    color: var(--color-dark);
                    margin: 0;
                }

                p {
                    color: #8d8d8d;
                    text-align: center;
                    margin-bottom: 10px;
                }

                form {
                    .form-check {
                        margin: 10px 0;

                        .form-check-input {
                            height: 20px;
                            width: 20px;
                            margin-top: 5px;


                            &:checked[type=checkbox] {
                                background-image: url('./assets/images/banner/tick.png');
                            }

                            &:checked[type=radio] {
                                background-image: url('./assets/images/banner/round.png');
                            }
                        }

                        .form-check-label {
                            width: 90%;
                            line-height: 18px;

                            p {
                                text-align: left;
                                color: var(--color-dark);
                            }
                        }
                    }
                }
            }

            .modal-footer {
                .total-check-foot {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    .button-div-all {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        column-gap: 15px;
                        width: 50%;

                        a {
                            svg {
                                height: 40px;
                                width: 40px;
                            }
                        }

                        h5 {
                            font-size: 1.5rem;
                            color: var(--color-dark);
                            margin: 0;
                        }

                    }

                    .anchor-div {
                        width: 50%;

                        a {
                            background-color: var(--color-c1);
                            color: var(--color-light);
                            font-size: 1.5rem;
                            padding: 8px 30px;
                            border-radius: 5px;
                            text-decoration: none;
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}

// business-popup-start
.switch-toggle.add-business {
    .add-business {
        text-align: center;
        font-weight: 600;
        color: #fff;
        border-radius: 5px;
        position: relative;
    }


    h6 {
        font-weight: 600;
        color: var(--color-dark);
    }

    .active {
        border: 1px solid var(--color-c1);
        background-color: var(--color-light);
        color: var(--color-dark);
    }
}

// business-popup-end
// menucard modal start here
.menucard-modal-details {
    .modal-body {
        padding: 0;

        .head-item {
            background-color: #cfcfcf29;
            position: relative;
            // border-bottom: 1px solid var(--color-dark);
            padding: 8px 15px;

            h4 {
                color: var(--color-c1);
                margin: 0;

            }

            .required {
                position: absolute;
                right: 4%;
                top: 23%;

                p {
                    padding: 2px 8px;
                    background-color: transparent;
                    margin: 0;
                    color: var(--color-dark);
                    border-radius: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.active {
                        background-color: var(--color-dark);
                        color: var(--color-light);
                        font-size: 12px;
                        letter-spacing: 1px;
                    }
                }
            }
        }

        .menucard-modal {
            form {
                .form-check {
                    display: flex;
                    align-items: inherit;
                    justify-content: left;
                    -moz-column-gap: 10px;
                    column-gap: 10px;

                    .form-check-input {
                        float: none;
                        margin: 0;
                        position: relative;
                        background-color: var(--color-light);
                        border-color: #b9b9b9;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

// menucard modal end here

// profile page html start here
.profile-page-details {
    padding: 40px 0 200px 0;
    align-items: baseline;
    justify-content: space-between;

    img {
        width: 30px;
    }

    .profile-tab-head {

        flex-direction: column;
        border: none;
        row-gap: 10px;
        background-color: var(--color-light);
        overflow: hidden;
        padding: 30px 20px;
        filter: drop-shadow(-3px 3px 6px rgba(112, 112, 112, 0.2705882353));

        li {
            width: 100%;

            button {
                width: 100%;
                display: flex;
                justify-content: left;
                align-items: center;
                column-gap: 10px;

                &.active {
                    background-color: var(--color-c1);

                    h6 {
                        color: var(--color-light);
                    }
                }

                img {
                    width: 30px;
                    height: 30px;
                }

                h6 {
                    margin: 0;
                    color: var(--color-dark);
                }


            }
        }
    }

    .profile-tab-body {
        .profile-section {
            .head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 10px;
                margin-bottom: 10px;
                border-bottom: 1px solid var(--mobile);

                h3 {
                    color: var(--color-dark);
                    margin: 0;
                    font-size: 1.2rem;
                }

                a {
                    padding: 6px 15px 7px 15px;
                    background-color: var(--green);
                    border-radius: 3px;
                    color: var(--color-light);
                    font-size: 1rem;

                    text-decoration: none svg {
                        width: 18px;
                        height: 18px;
                        fill: var(--color-light);
                        position: relative;
                        bottom: 3px;
                        margin: 0 5px 0 0;
                        background-color: transparent;
                        padding: 0;
                    }
                }

                // span.verify {
                //     margin: 0 0 0 0px;
                // }
            }

            .body {
                align-items: start;
                justify-content: center;
                -moz-column-gap: 40px;
                column-gap: 40px;
                flex-direction: column;
                row-gap: 10px;

                .profile-update {
                    form {


                        input[type="file"] {
                            display: none;
                            cursor: pointer;
                        }
                    }
                }

                .profile-details {
                    display: flex;
                    align-items: flex-start;
                    justify-content: left;
                    column-gap: 10px;

                    h6 {
                        font-size: 1.2rem;
                        color: var(--color-dark);
                        margin: 0;

                    }

                    h6 {
                        span {
                            font-size: 1.125rem;
                            color: var(--color-dark);
                            margin: 0;
                        }

                    }
                }
            }
        }

        .account-prefference {
            .head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 10px;
                margin-bottom: 10px;
                border-bottom: 1px solid var(--mobile);

                h3 {
                    color: var(--color-dark);
                    font-size: 1.3rem;
                    margin: 0;
                }

                a {
                    padding: 6px 15px 7px 15px;
                    background-color: var(--green);
                    border-radius: 3px;
                    color: var(--color-light);
                    font-size: 1rem;
                    text-decoration: none;
                }

                svg {
                    width: 18px;
                    height: 18px;
                    fill: var(--color-light);
                    position: relative;
                    bottom: 3px;
                    margin: 0 5px 0 0;
                    background-color: transparent;
                    padding: 0;
                }
            }

            .body {
                .profile-details {
                    display: flex;
                    align-items: flex-start;
                    justify-content: left;
                    -moz-column-gap: 10px;
                    column-gap: 11px;
                    /* row-gap: 15px; */
                    width: 100%;
                    margin: 0 auto;
                    margin-bottom: 10px;

                    h6 {
                        color: var(--color-dark);
                        margin: 0;
                        font-size: 1.2rem;
                    }

                    h6 {
                        span {
                            color: var(--color-dark);
                            margin: 0;
                            line-height: 22px;
                            font-size: 1.125rem;
                        }

                        span.verify {
                            color: var(--color-c1);
                        }

                        a {
                            color: var(--color-c1);
                            margin-left: 10px;
                            text-decoration: none;
                            font-weight: 700;
                        }

                    }
                }
            }
        }

        .password {
            .head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 10px;
                margin-bottom: 10px;
                border-bottom: 1px solid var(--mobile);

                h3 {
                    color: var(--color-dark);
                    font-size: 1.3rem;
                    margin: 0;
                }

                a {
                    padding: 6px 15px 7px 15px;
                    background-color: var(--color-c1);
                    border-radius: 3px;
                    color: var(--color-light);
                    font-size: 1rem;
                    text-decoration: none;

                    svg {
                        width: 18px;
                        height: 18px;
                        fill: var(--color-light);
                        position: relative;
                        bottom: 3px;
                        margin: 0 5px 0 0;
                        padding: 0;
                        background-color: transparent;
                    }
                }
            }

            .body {
                .profile-details {
                    display: flex;
                    align-items: flex-start;
                    justify-content: left;
                    -moz-column-gap: 10px;
                    column-gap: 11px;
                    /* row-gap: 15px; */
                    width: 100%;
                    margin: 0 auto;
                    margin-bottom: 10px;

                    h6 {
                        span {
                            color: var(--color-dark);
                            margin: 0;
                        }

                        a {
                            color: var(--color-c1);
                            margin-left: 10px;
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .no-data-sec {
            h3 {
                color: var(--color-c1);
                font-size: 1.5rem;
                margin: 0;
                text-align: center;
            }
        }

        .address-book {
            .head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 10px;
                margin-bottom: 10px;
                border-bottom: 1px solid var(--mobile);

                h3 {
                    color: var(--color-c1);
                    font-size: 1.5rem;
                    margin: 0;
                }

                a {
                    padding: 3px 10px;
                    background-color: var(--color-c1);
                    border-radius: 3px;
                    color: var(--color-light);
                    font-size: 1rem;
                    text-decoration: none;
                }
            }

            .body {
                .address-div {
                    row-gap: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    flex-wrap: wrap;
                    width: 100%;
                    column-gap: 15px;

                    .card {
                        width: 32%;
                        padding: 10px;
                        height: 140px;
                        position: relative;
                        overflow: hidden;
                        transition: all 1s;

                        h3 {
                            color: var(--color-c1);
                            margin: 0;
                            font-size: 1rem;
                        }

                        p {
                            margin: 0;
                        }

                        .phone {
                            display: flex;
                            align-items: center;
                            justify-content: left;
                            column-gap: 10px;
                            margin-top: 7px;

                            img {
                                width: 15px;
                                height: 15px;
                            }

                            p {}
                        }

                        .mail {
                            display: flex;
                            align-items: center;
                            justify-content: left;
                            column-gap: 10px;

                            img {
                                width: 20px;
                                height: 20px;
                            }

                        }

                        .edit {
                            position: absolute;
                            top: 1%;
                            right: 1%;
                        }

                        .add {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .delete-div {
                            position: absolute;
                            bottom: -50px;
                            // width: 100%;
                            left: 0;
                            right: 0;

                            a {
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                background-color: #00000086;
                                padding: 10px;
                                align-items: center;
                                column-gap: 8px;
                                text-decoration: none;

                                svg {
                                    height: 25px;
                                    width: 25px;
                                    fill: var(--color-light);
                                }

                                p {
                                    color: var(--color-light);
                                    margin: 0;
                                }
                            }
                        }

                        &:hover {
                            .delete-div {
                                bottom: 0px;
                                transition: all 0.6s ease-in-out;
                            }
                        }
                    }
                }
            }
        }
    }
}

// profile page html end here
// floating button start here
.floating-button-menu {
    z-index: 2;
    position: fixed;
    bottom:10%;
    right: 10px;
    cursor: pointer;
    background: var(--color-c1);
    border-radius: 0px;
    min-width: 75px;
    max-width: 0px;
    min-height: 27px;
    max-height: 0px;
    box-shadow: 2px 2px 8px 2px rgba(241, 241, 241, 0.6);
    transition: all ease-in-out 0.6s;
    align-items: center;
    justify-content: center;
    display: none;
    border: 1px solid var(--color-c1);

    &:hover {
        background: #fff;
    }

    .floating-button-menu-links {
        width: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: all .4s;

        a {
            position: relative;
            color: #454545;
            text-transform: uppercase;
            text-decoration: none;
            line-height: 50px;
            display: block;
            display: block;
            border-bottom: 1px solid #ccc;
            width: 100%;
            padding: 0 20px;
            border-bottom: 1px solid #ccc;
            transition: background ease-in-out .3s;
            background: rgba(0, 0, 0, 0);

            p {
                transition: all 0.5s ease-in-out;
                color: var(--color-dark);
                text-decoration: none;
            }

            &:hover {
                background: rgba(0, 0, 0, .1);
                border-left: 3px solid var(--color-c1);

                p {
                    color: var(--color-c1);
                    padding-left: 5px;
                }

            }

            &:last-child {
                border-bottom: 0px solid #fff;
            }
        }

        &.menu-on {
            background: #fff;
            width: 450px;
            height: 400px;
            border-radius: 10px;
            opacity: 1;
            transition: all ease-in-out .5s;
        }

    }

    .floating-button-menu-label {
        text-align: center;
        line-height: 64px;
        font-size: 40px;
        color: #fff;
        opacity: 1;
        transition: opacity .3s;

        h5 {
            color: var(--color-light);
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 2px;

            span {
                img {
                    height: 15px;
                    width: 15px;
                }
            }
        }
    }

    &.menu-on {
        background: #fff;
        max-width: 340px;
        max-height: 320px;
        border-radius: 10px;
        overflow-x: scroll;

        .floating-button-menu-links {
            width: 100%;
            height: 100%;
            opacity: 1;
            transition: all ease-in-out 1s;
        }

        .floating-button-menu-label {
            height: 0px;
            overflow: hidden;
        }
    }
}

.floating-button-menu-close {
    position: fixed;
    z-index: 2;
    width: 0%;
    height: 0%;

    &.menu-on {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .1);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

// floating button end here
.floating-busket-button {
    background-color: var(--color-c1);
    position: fixed;
    bottom: 68px;
    width: 80%;
    left: 14px;
    border-radius: 41px;
    z-index: 99999999999;
    border: 2px solid var(--color-dark);

    a {
        background-color: var(--color-c1);
        display: flex;
        padding: 12px;
        justify-content: center;
        border-radius: 40px;
        align-items: center;
        column-gap: 10px;
        text-decoration: none;

        p {
            margin: 0;
            color: var(--color-light);
        }

        .svg-div {
            position: relative;

            svg {
                fill: var(--color-light);
                height: 25px;
                width: 25px;
            }

            span {
                position: absolute;
                top: -21%;
                left: -52%;
                background-color: var(--color-dark);
                color: var(--color-light);
                font-size: 10px;
                padding: 3px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 20px;
                width: 20px;
            }
        }
    }
}

// .delivery-collection page start here
.delivery-collect {
    padding: 100px 0;

    .button-sec {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: left;

        .back {
            a {
                display: flex;
                align-items: center;
                justify-content: left;
                column-gap: 5px;
                color: var(--color-dark);
                text-decoration: none;

                span {
                    color: var(--color-dark);
                }

            }
        }
    }

    .menu-cash-total {
        background-color: var(--color-light);
        border-radius: 5px;
        height: fit-content;
        position: sticky;
        top: 80px;
        // filter: drop-shadow(-3px 3px 6px rgba(112, 112, 112, 0.45));
        box-shadow: 0 4px 6px 0 rgba(27, 35, 36, .02), 0 2px 12px -2px rgba(27, 35, 36, .08), 0 3px 6px 0 rgba(27, 35, 36, .06);

        .note-div {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 10px;
            padding: 10px;
            color: var(--star);
            background-color: var(--back-yellow);
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            p {
                margin: 0;
                color: var(--color-dark);
            }

            svg {
                fill: var(--star);
            }
        }

        .allergies {
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: left;
            column-gap: 10px;

            svg {
                fill: var(--color-dark);
            }

            p {
                color: var(--color-dark);
                margin: 0;
            }
        }

        .delivery-collection {
            padding: 0px 10px 15px 10px;

            .switch-toggle {
                .total-div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    -moz-column-gap: 8px;
                    column-gap: 8px;

                    .left-div {
                        svg {
                            height: 25px;
                            width: 25px;
                            fill: var(--color-dark);
                        }
                    }
                }
            }

            .tab-slider--nav {
                width: 100%;
                float: left;
                margin-bottom: 20px;

                .tab-slider--tabs {
                    display: block;
                    float: left;
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    position: relative;
                    border-radius: 5px;
                    overflow: hidden;
                    background: #e3e3e3;
                    height: 55px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    user-select: none;
                    width: 100%;
                    filter: drop-shadow(-3px 3px 6px rgba(112, 112, 112, 0.2));

                    &::after {
                        content: "";
                        width: 49%;
                        background: var(--color-c1);
                        height: 88%;
                        position: absolute;
                        top: 3px;
                        left: 3px;
                        transition: all 250ms ease-in-out;
                        border-radius: 5px;
                    }

                    &.slide {
                        &::after {
                            left: 50%;
                        }
                    }

                    .tab-slider--trigger {
                        font-size: 12px;
                        line-height: 1;
                        font-weight: bold;
                        color: var(--color-light);
                        text-transform: uppercase;
                        text-align: center;
                        padding: 10px 12px;
                        position: relative;
                        z-index: 2;
                        cursor: pointer;
                        display: inline-block;
                        transition: color 250ms ease-in-out;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        width: 48%;

                        &.active {

                            .total-div {
                                .left-div {
                                    svg {
                                        height: 25px;
                                        width: 25px;
                                        fill: var(--color-light);
                                    }
                                }

                                .right-div {
                                    h6 {
                                        color: var(--color-light);
                                    }

                                    p {
                                        color: var(--color-light);
                                    }
                                }
                            }
                        }

                        .total-div {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            column-gap: 8px;

                            .left-div {
                                svg {
                                    height: 25px;
                                    width: 25px;
                                    fill: var(--color-dark);
                                }
                            }

                            .right-div {
                                h6 {
                                    margin: 0;
                                    text-align: left;
                                    color: var(--color-dark);
                                }

                                p {
                                    margin: 0;
                                    text-transform: capitalize;
                                    text-align: left;
                                    color: var(--color-dark);
                                }
                            }
                        }

                    }
                }
            }

            .tab-slider--body {
                .second-content {
                    .eliment-content {
                        width: 100%;
                        font-size: 17px;
                        text-transform: uppercase;

                        .item-name {
                            width: 30%;
                            text-align: left;
                        }

                        .item-middle {
                            width: 50%;
                        }

                        .item-price {
                            width: 20%;
                            text-align: right;
                        }
                    }

                    .item-product-details {
                        width: 100%;
                        padding-bottom: 15px;
                        margin-bottom: 10px;
                        border-bottom: 1px solid var(--modal);

                        .plus-minus {
                            width: 20%;
                            display: flex;
                            align-items: center;
                            justify-content: left;
                            column-gap: 8px;

                            h5 {
                                margin-top: 3px;
                                margin-bottom: 0;
                                color: var(--green);
                            }
                        }

                        .item-name {
                            width: 60%;

                            h5 {
                                text-align: left;
                                text-transform: uppercase;
                                margin: 0;
                            }

                            p {
                                margin: 0;
                            }

                            h6 {
                                padding-left: 10px;
                                margin-bottom: 0;
                                margin: 0;
                            }
                        }

                        .price-tag {
                            width: 20%;
                            text-align: right;
                            vertical-align: top;
                        }
                    }

                    .free-dish-top {
                        width: 100%;
                        margin-bottom: 20px;

                        .free-title {
                            margin-bottom: 15px;

                            h6 {
                                width: 100%;
                                background-color: var(--button-back);
                                padding: 10px;
                                border: 1px solid var(--green);
                                border-radius: 5px;
                                color: var(--green);
                            }
                        }
                    }

                    .free-total-div {
                        background-color: #f3f3f3;
                        padding: 20px 5px;
                        border-radius: 5px;

                        .free-dish-items {
                            width: 100%;
                            position: relative;

                            .items {
                                width: 70%;
                                padding: 3px 3px 3px 10px;
                                text-align: left;

                                span {
                                    color: var(--green);
                                }
                            }

                            .price {
                                width: 30%;
                                padding: 3px 10px 3px 3px;
                                text-align: right;
                                color: var(--color-c1);
                                text-decoration: line-through;
                            }

                            .edit-btn {
                                position: absolute;
                                top: -30%;
                                right: 0%;
                                height: 25px;
                                width: 25px;

                                a {
                                    background-color: #fff;
                                    padding: 4px;
                                    height: 30px;
                                    width: 30px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border-radius: 3px;
                                }
                            }
                        }
                    }

                    .Sub-total-sec {
                        width: 100%;
                        font-size: 17px;
                        margin-bottom: 10px;
                        border-bottom: 1px solid var(--modal);

                        .name {
                            width: 70%;
                            text-align: left;
                        }

                        .price {
                            width: 30%;
                            text-align: right;
                        }
                    }

                    .total-price {
                        width: 100%;
                        font-size: 18px;
                        margin-top: 5px;

                        .total-p {
                            width: 70%;
                            text-align: left;
                        }

                        .price {
                            width: 30%;
                            text-align: right;
                        }
                    }

                    .input-sec {
                        margin-top: 15px;

                        form {
                            position: relative;

                            input {
                                box-shadow: none;
                                padding: 10px 80px 10px 10px;
                            }

                            a {
                                color: var(--color-c1);
                                font-size: 17px;
                                position: absolute;
                                right: 1%;
                                top: 15%;
                                text-decoration: none;
                            }
                        }
                    }

                    .anchor-tag {
                        a {
                            width: 100%;
                            background-color: var(--color-c1);
                            color: var(--color-light);
                            font-size: 18px;
                            padding: 10px;
                            text-align: center;
                            text-decoration: none;
                            display: flex;
                            justify-content: center;
                            border-radius: 5px;
                            filter: drop-shadow(-3px 3px 6px rgba(112, 112, 112, 0.15));
                        }
                    }

                    .booking-side-panel {
                        width: 100%;
                        margin-top: 10px;

                        .book-panel-left {
                            width: 30%;
                            margin-bottom: 10px;
                            border-bottom: 1px solid var(--modal);

                            h6 {
                                color: var(--color-dark);
                                text-align: left;
                                padding-top: 10px;
                                padding-bottom: 10px;
                                margin: 0;
                                padding-right: 5px;
                            }

                            p {
                                color: var(--color-dark);
                                margin: 0;
                                padding-right: 5px;
                                padding-top: 10px;
                                padding-bottom: 10px;

                                span {
                                    font-size: 15px;
                                    color: var(--color-dark);
                                    margin: 0;
                                    padding-right: 5px;
                                }
                            }

                            span {
                                font-size: 15px;
                                color: var(--color-dark);
                                margin: 0;
                                padding-right: 5px;
                            }
                        }

                        .book-panel-right {
                            width: 70%;
                            margin-bottom: 10px;
                            border-bottom: 1px solid var(--modal);

                            h6 {
                                color: var(--color-dark);
                                text-align: right;
                                margin: 0;
                                padding-bottom: 5px;
                                padding-left: 5px;
                                padding-top: 10px;
                                padding-bottom: 10px;
                            }

                            p {
                                color: var(--color-dark);
                                text-align: right;
                                margin: 0;
                                padding-left: 5px;
                                padding-top: 10px;
                                padding-bottom: 10px;
                            }
                        }

                        .full-wid {
                            width: 100%;
                            margin-bottom: 10px;
                            border-bottom: 1px solid var(--modal);
                            column-gap: 10px;
                        }
                    }
                }
            }
        }
    }
}

// .delivery-collection page end here
.cart-second-content {
    .eliment-content {
        width: 100%;
        font-size: 1rem;
        text-transform: uppercase;

        .item-name {
            width: 30%;
            text-align: left;
        }

        .item-middle {
            width: 50%;
        }

        .item-price {
            width: 20%;
            text-align: right;
        }
    }

    .item-product-details {
        width: 100%;
        padding-bottom: 15px;
        margin-bottom: 10px;
        border-bottom: 1px solid var(--modal);

        .plus-minus {
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: left;
            column-gap: 8px;

            h5 {
                margin-top: 3px;
                margin-bottom: 0;
                color: var(--green);
            }
        }

        .item-name {
            width: 60%;

            h5 {
                text-align: left;
                text-transform: uppercase;
                margin: 0;
            }

            p {
                margin: 0;
            }

            h6 {
                padding-left: 10px;
                margin-bottom: 0;
                margin: 0;
            }
        }

        .price-tag {
            width: 20%;
            text-align: right;
            vertical-align: top;
        }
    }

    .free-dish-top {
        width: 100%;
        margin-bottom: 20px;

        .free-title {
            margin-bottom: 15px;

            h6 {
                width: 100%;
                background-color: var(--button-back);
                padding: 10px;
                border: 1px solid var(--green);
                border-radius: 5px;
                font-size: 17px;
                color: var(--green);
            }
        }
    }

    .free-total-div {
        background-color: #f3f3f3;
        padding: 20px 5px;
        border-radius: 5px;

        .free-dish-items {
            width: 100%;
            position: relative;

            .items {
                width: 70%;
                padding: 3px 3px 3px 10px;
                text-align: left;

                span {
                    color: var(--green);
                }
            }

            .price {
                width: 30%;
                padding: 3px 10px 3px 3px;
                text-align: right;
                color: var(--color-c1);
                text-decoration: line-through;
            }

            .edit-btn {
                position: absolute;
                top: -30%;
                right: 0%;
                height: 25px;
                width: 25px;

                a {
                    background-color: #fff;
                    padding: 4px;
                    height: 30px;
                    width: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 3px;
                }
            }
        }
    }

    .Sub-total-sec {
        width: 100%;
        font-size: 17px;
        margin-bottom: 10px;
        border-bottom: 1px solid var(--modal);

        .name {
            width: 70%;
            text-align: left;
        }

        .price {
            width: 30%;
            text-align: right;
        }
    }

    .total-price {
        width: 100%;
        font-size: 18px;
        margin-top: 5px;

        .total-p {
            width: 70%;
            text-align: left;
        }

        .price {
            width: 30%;
            text-align: right;
        }
    }

    .input-sec {
        margin-top: 15px;

        form {
            position: relative;

            input {
                box-shadow: none;
                padding: 10px 80px 10px 10px;
            }

            a {
                color: var(--color-c1);
                font-size: 17px;
                position: absolute;
                right: 1%;
                top: 15%;
                text-decoration: none;
            }
        }
    }

    .anchor-tag {
        a {
            width: 100%;
            background-color: var(--color-c1);
            color: var(--color-light);
            font-size: 18px;
            padding: 10px;
            text-align: center;
            text-decoration: none;
            display: flex;
            justify-content: center;
            border-radius: 5px;
            filter: drop-shadow(-3px 3px 6px rgba(112, 112, 112, 0.15));
        }
    }

    .booking-side-panel {
        width: 100%;
        margin-top: 10px;

        .book-panel-left {
            width: 30%;
            margin-bottom: 10px;
            border-bottom: 1px solid var(--modal);

            h6 {
                color: var(--color-dark);
                text-align: left;
                padding-top: 10px;
                padding-bottom: 10px;
                margin: 0;
                padding-right: 5px;
            }

            p {
                color: var(--color-dark);
                margin: 0;
                padding-right: 5px;
                padding-top: 10px;
                padding-bottom: 10px;

                span {
                    color: var(--color-dark);
                    margin: 0;
                    padding-right: 5px;
                }
            }

            span {
                font-size: 15px;
                color: var(--color-dark);
                margin: 0;
                padding-right: 5px;
            }
        }

        .book-panel-right {
            width: 70%;
            margin-bottom: 10px;
            border-bottom: 1px solid var(--modal);

            h6 {
                color: var(--color-dark);
                text-align: right;
                margin: 0;
                padding-bottom: 5px;
                padding-left: 5px;
                padding-top: 10px;
                padding-bottom: 10px;
            }

            p {
                color: var(--color-dark);
                text-align: right;
                margin: 0;
                padding-left: 5px;
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }

        .full-wid {
            width: 100%;
            margin-bottom: 10px;
            border-bottom: 1px solid var(--modal);
            column-gap: 10px;
        }
    }
}

// suchita 19.08.2024
.menu-category-hide {
    display: none;
}

.tab-details {
    width: 300px;
    max-width: 100%;
}

.tab-content {
    // width: 800px;
    max-width: 100%;
}

.tabContent .row>* {
    /* flex-shrink: 0; */
    width: inherit;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-top: var(--bs-gutter-y);

    #myProfile {
        background-color: var(--color-light);
        filter: drop-shadow(-3px 3px 6px rgba(112, 112, 112, 0.2705882353));
    }

    h6 {
        position: relative;
        bottom: -3px;
    }

    .edit {
        background-color: var(--green);
        border: none;

        svg {
            fill: var(--color-light);
            height: 21PX;
            POSITION: RELATIVE;
            BOTTOM: 3PX;
        }
    }

    a:hover {
        color: var(--color-dark);
    }

    a.anchor.del.ml-1.btn:hover {
        color: var(--color-light);
    }

    a.anchor.edit.ml-2.btn:hover {
        color: var(--color-light);

    }
}

.profile-page-details .profile-tab-head svg {
    width: 24px;
    height: 24px;
    float: left;
}

.profile-page-details .profile-tab-head li.active a {
    color: var(--color-light);
}

.profile-page-details .profile-tab-head li.active a svg {
    fill: var(--color-light);
    margin-right: 10px;
}

.profile-page-details .profile-tab-head li a {
    color: var(--color-dark);
}

.profile-page-details .profile-tab-head li a svg {
    fill: var(--color-dark);
    margin-right: 10px;
}

.tab-content .menu-offer {
    max-width: 100%;
    width: 914px;

    padding: 0px;
}

.review-section .card {
    max-width: 100%;
    width: 914px;
    box-shadow: 0 4px 6px 0 rgba(27, 35, 36, 0.02), 0 2px 12px -2px rgba(27, 35, 36, 0.08), 0 3px 6px 0 rgba(27, 35, 36, 0.06);
    padding: 30px;

    .content {
        border-bottom: 1px solid var(--checkout);
        margin-bottom: 20px;

        a.anchor.del.ml-1.btn:hover {
            border: 1px solid var(--color-c1);
            color: var(--color-c1);
        }

    }
}



.profile-page-details .left-panel.col-lg-2 {
    width: 15%;
}

.profile-page-details .middle-panel.col-lg-7 {
    width: 40%;

    a.btn.booking {
        margin: 0;
    }

    .text {
        strong a {
            color: var(--color-c1);
        }
    }
}

p.btn1 {
    font-size: 1.2rem;
    font-weight: 500;
}

p.btn1 a {
    color: var(--color-c1);
}

.profile-page-details .right-panel.col-lg-3 {
    width: 45%;
    text-align: right;

    .item-button {
        a:hover {
            color: var(--color-light);
        }
    }
}

.item-button a {
    border: 1px solid var(--color-c1);
    color: var(--color-c1);
    background-color: var(--color-light);

}

.item-button a:hover {
    background-color: var(--color-c1);
    color: var(--color-light);
}

.btn {
    background-color: var(--color-c1);
    color: var(--color-light);
    margin-bottom: 10px;
    margin-left: 10px;
    border: none;
    border: 1px solid var(--color-c1);

    &:hover {
        border: 1px solid var(--color-c1);
        background-color: transparent;
        color: var(--color-c1);
    }
}

.btn a {
    color: var(--color-light);
}

.card .align-items-center {
    align-items: end !important;
}

p.btn {
    margin: 0;
}

.no-data-open.card {
    text-align: center;
    max-width: 100%;
    width: 916px;
}

.inner-banner {
    position: relative;
    background-size: cover;
    height: 220px;
    width: 100%;
    padding: 0;
}

.inner-banner h1 {
    position: absolute;
    top: 33%;
    letter-spacing: 5px;
    color: dimgray;
    text-align: center;
    width: 100%; font-size: 3rem;
}

.inner-banner h1 span {
    color: var(--color-c1);
    font-size: 3rem;
}

// new-floating-icon
.floating-button-menu {
    z-index: 2;
    position: fixed;
    bottom: 10%;
    right: 18px;
    cursor: pointer;
    background: var(--color-c1);
    border-radius: 6px;
    min-width: 75px;
    max-width: 0px;
    min-height: 27px;
    max-height: 0px;
    box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.6);
    transition: all ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}

.floating-button-menu:hover {
    background: #f03157;
}

.floating-button-menu .floating-button-menu-links {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 0.4s;
}

.floating-button-menu .floating-button-menu-links a {
    position: relative;
    color: #454545;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 50px;
    display: block;
    display: block;
    border-bottom: 1px solid #ccc;
    width: 100%;
    padding: 0 20px;
    border-bottom: 1px solid #e8e8e8;
    transition: background ease-in-out 0.3s;
    background: rgba(0, 0, 0, 0); padding: 10px;
}

.floating-button-menu .floating-button-menu-links a p {
    transition: all 0.5s ease-in-out;
    color: var(--color-dark);
    text-decoration: none;
    font-size: 16px;
    line-height: 16px;
    padding-top: 6px;
}

.floating-button-menu .floating-button-menu-links a:hover {
    background: rgba(0, 0, 0, 0.1);
    border-left: 3px solid var(--color-c1);
}

.floating-button-menu .floating-button-menu-links a:hover p {
    color: var(--color-c1);
    padding-left: 5px;
}

.floating-button-menu .floating-button-menu-links a:last-child {
    border-bottom: 0px solid #fff;
}

.floating-button-menu .floating-button-menu-links.menu-on {
    background: #fff;
    width: 450px;
    height: 400px;
    border-radius: 10px;
    opacity: 1;
    transition: all ease-in-out 0.5s;
}

.floating-button-menu .floating-button-menu-label {
    text-align: center;
    line-height: 64px;
    font-size: 40px;
    color: #fff;
    opacity: 1;
    transition: opacity 0.3s;
}

.floating-button-menu .floating-button-menu-label h5 {
    font-size: 15px;
    color: var(--color-light);
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    -moz-column-gap: 2px;
    column-gap: 2px;
    padding-top: 3px;
}

.floating-button-menu .floating-button-menu-label h5 span img {
    height: 15px;
    width: 15px;
    display: none;
}

.floating-button-menu.menu-on {
    background: #fff;
    max-width: 340px;
    max-height: 320px;
    border-radius: 5px;
    overflow-x: scroll;
    border: none; padding: 30px;
    box-shadow: 0px 0 5px 4px #e2dfdf;
}

.cross-btn {
    float: right;
    position: absolute;
    margin: -20px 0 0 0;
    right: 13px;
    font-size: 1.3rem;
    border: 1px solid #cccccc;
    height: 30px;
    color: #fff;
    width: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-light);
    svg{ fill: var(--color-c1);}
}

.floating-button-menu.menu-on .floating-button-menu-links {
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: all ease-in-out 1s;
}

.floating-button-menu.menu-on .floating-button-menu-label {
    height: 0px;
    overflow: hidden;
}

.floating-button-menu-close {
    position: fixed;
    z-index: 2;
    width: 0%;
    height: 0%;
}

.floating-button-menu-close.menu-on {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.floating-busket-button {
    background-color: var(--color-c1);
    position: fixed;
    bottom: 68px;
    width: 80%;
    left: 14px;
    border-radius: 41px;
    z-index: 99999999999;
    border: 2px solid var(--color-dark);
}

.floating-busket-button a {
    background-color: var(--color-c1);
    display: flex;
    padding: 12px;
    justify-content: center;
    border-radius: 40px;
    align-items: center;
    -moz-column-gap: 10px;
    column-gap: 10px;
    text-decoration: none;
}

.floating-busket-button a p {
    margin: 0;
    font-size: 15px;
    color: var(--color-light);
}

.floating-busket-button a .svg-div {
    position: relative;
}

.floating-busket-button a .svg-div svg {
    fill: var(--color-light);
    height: 25px;
    width: 25px;
}

.floating-busket-button a .svg-div span {
    position: absolute;
    top: -21%;
    left: -52%;
    background-color: var(--color-dark);
    color: var(--color-light);
    font-size: 10px;
    padding: 3px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
}

.Copyright-footer {
    background-color: rgb(0 0 0);
    padding: 10px;
    position: relative;
    margin: 20px 0 0 0;
}

// new-floating-icon
.error404 {
    min-height: calc(100vh - 202px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error404 h1 {
    font-size: 10vw;
    line-height: 10vw;
    color: var(--color-c1);
    margin: 0;
}

.error404 p {
    font-size: 14px;
    color: var(--color-dark);
    margin: 0 0 20px;
}

.error404 .btn {
    margin: 0 10px;
}

.addbtn a {
    background-color: var(--checkout);
    text-align: center;
    color: var(--color-dark);
    border-radius: 0.5rem;
    padding: 10px 10px 0;
    font-size: 0.9rem;
    justify-content: center;
}

.addbtn a span {
    margin-bottom: 0;
    margin-left: 10px;
}

// tab//
.faq-drawer {
    margin-bottom: 30px;
}

.faq-drawer__content-wrapper {
    font-size: 1.25em;
    line-height: 1.4em;
    max-height: 0px;
    overflow: hidden;
    transition: 0.25s ease-in-out;
}

.faq-drawer__title {
    border-bottom: 1px solid var(--color-light);
    cursor: pointer;
    display: block;
    font-size: 1.25em;
    font-weight: 700;
    padding: 20px 0 30px 0;
    position: relative;
    margin-bottom: 0;
    border-radius: 5px;
    transition: all 0.25s ease-out;
    background-color: var(--color-pink);
}

.faq-drawer__title::after {
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: " ";
    display: inline-block;
    float: right;
    height: 7px;
    left: -16px;
    position: relative;
    right: 20px;
    top: 0px;
    transform: rotate(135deg);
    transition: 0.35s ease-in-out;
    vertical-align: top;
    width: 7px;
    color: var(--color-dark);

}

.tab-function span {
    margin: 0 0 0 25px;
}

/* OPTIONAL HOVER STATE */
.faq-drawer__title:hover {
    color: #4E4B52;
}

.faq-drawer__trigger:checked+.faq-drawer__title+.faq-drawer__content-wrapper {
    max-height: 600px;
}

.faq-drawer__trigger:checked+.faq-drawer__title::after {
    transform: rotate(-45deg);
    transition: 0.25s ease-in-out;
}

.faq-drawer__trigger {
    display: none;
}

.tab-function {
    position: relative;
}

.item-tab {
    position: absolute;
    top: 0;
    width: 100%;
    top: 0;
    padding: 0 0 0 10px;

    svg {
        background: var(--all);
        border-radius: 15%;
        width: 30px;
        height: 30px;
        padding: 5px;
        fill: var(--color-c1);


    }
}

.item-tab span {
    color: var(--checkout);
}

span.item-acor {
    color: var(--button-dark);
    margin: 0 0 0 10px;
    font-size: 16px;
    font-weight: 700;
}

.tab-content1 span {
    margin: 0;
}

.icon-tab span {
    margin: 0;
}

.icon-tab {
    background-color: var(--all);
    text-align: center;
    height: 30px;
    width: 30px;
    border-radius: 5px;
}

.tab-content1 h6 {
    color: var(--checkout1);
}

.tab-content1 span {
    color: var(--checkout2);
}

.faq-drawer__content {
    margin-top: 30px;
}

//   
// skeleton loader
.skeleton-box {
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #DDDBDD;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(90deg,
                rgba(#fff, 0) 0,
                rgba(#fff, 0.2) 20%,
                rgba(#fff, 0.5) 60%,
                rgba(#fff, 0));
        animation: shimmer 5s infinite;
        content: '';
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}

.blog-post {
    &__headline {
        font-size: 1.25em;
        font-weight: bold;
    }

    &__meta {
        font-size: 0.85em;
        color: #6b6b6b;
    }
}

// OBJECTS

.o-media {
    display: flex;

    &__body {
        flex-grow: 1;
        margin-left: 1em;
    }
}

.o-vertical-spacing {
    >*+* {
        margin-top: 0.75em;
    }

    &--l {
        >*+* {
            margin-top: 2em;
        }
    }
}

// MISC

main {
    margin-top: 3em;
}

header {
    h1 {
        font-family: 'Rubik', sans-serif;
        font-weight: 500;
        line-height: 1.2;
        font-size: 2em;
    }

    p {
        &:not(:first-child) {
            margin-top: 1em;
        }
    }
}

.button-sec.bottom {
    background-color: var(--green);
    padding: 10px;
    border-radius: 5px;
}

.button-sec.bottom a {
    color: var(--color-light);
    width: 100%;
    text-align: center;
}

// skeleton loader

@media only screen and (max-width: 600px) {
    .container {
        padding: 80px;
    }
}

@media screen and (max-width:991) {
    .menu-fulldetails .menu-fulldetails-row .menu-items {
        display: none;
    }
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    pointer-events: none;
    opacity: .65;
    padding: 7px;
}

.nav-btn.prev-slide {
    position: absolute;
    background-image: url("./assets/images/checkout/previous.png");
    top: 40%;
    width: 20px;
    height: 19px;
    left: -11px;
}

.nav-btn.next-slide {
    position: absolute;
    background-image: url("./assets/images/checkout/next.png");
    top: 40%;
    width: 20px;
    height: 19px;
    right: -6px;
}

.btn-close {
    background-image: url(./assets/images/close/close-btn.png);
    background-repeat: no-repeat;
}

.modal-header .btn-close {
    margin: 0;
    border: 2px solid #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    opacity: 1; box-shadow: none;
}

.totals.last {
    background-color: var(--hover);
    margin: 5px 0 5px 0;
    border-radius: 5px;
}

.totals.last strong {
    color: var(--color-c1);
    padding: 8px;
}

.alert-danger {
    padding: 0;
    color: var(--color-red);
    background-color: transparent;
    border: none;
}

.banner-details.col-lg-8 {
    text-shadow: 2px 2px 4px var(--color-dark);
    margin-top: 0px;
}

div#myProfile {
    padding: 30px;
}

.tab-content1 {
    background: (var(--all));
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
}

strong.freedish {
    color: var(--green);
    margin: 0 0 0 10px;
    text-transform: uppercase;
}

.mobileCartPopup {
    display: none;
}

.form-control:focus {
    box-shadow: none;
}
.item.model-freedish input[type=checkbox]:checked + label:before {
    background-color: #ED820A;
    border-color: #ED820A;
    color: #fff;
}

.add-ons-menu-required{
    background-color: #ff000024;
    color: #fff;
    padding: 2px 15px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    border-radius: 5px;
    border: 1px solid red;
    color: red;
    font-size: 13px;
    line-height: 14px;
}
.optionerrormsgouterclass{
    display: none;
}