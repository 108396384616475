@font-face {
  font-family: myfont1;
  src: url('../font/Lato-Light.ttf');
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


:root {
  --color-c1: #f03157;
  --color-c1-low: #f0315729;
  --color-light: #fff;
  --color-dark: #464646;
  --color-pink: #ffbecb;
  --color-pink1: #ee6a81;
  --color-dark1:#6b6b6b;
  --color-red:#ff1e1e;
  --ass: #131313;
  --ass-light: #131313;
  --back: #4972fa;
  --all: #f1f1f1;
  --body: #f7f7f7;
  --green: #228f00;
  --gray: #837359;
  --gray1: #bebebe;
  --green-low: #218f0059;
  --mobile: #dbe1ea;
  --modal: #d3d3d3;
  --sec: #e3e6ed;
  --line: #c1c1c1;
  --hover: #e8eef7;
  --filter: #efefef;
  --star: #ff8e09;
  --back-yellow: #ffddb1;
  --button-back: #caffd5;
  --btn-red: #b40000;
  --btn-green: #005c03;
  --p-font: 0.938rem;
  --h1: 2.5rem;
  --h4: 1rem;
  --h3: 1.375rem;
  --h6: 1rem;
  --h5: 1.063rem;
  --span: 0.895rem;
  --strong: var(--color-c1);
  --font1: myfont1;
  --checkout:rgb(205 205 205);
  --checkout1:rgb(127, 127, 127);
  --checkout2:rgb(114, 114, 114);
  --font2:"Roboto", sans-serif;
}


/* Use body the variables */
body {
  background-color: var(--color-light);
  color: var(--color-dark);
  font-family: "Outfit", sans-serif;
  overflow-y: scroll;

  p {
    color: var(--color-dark);
    font-size: var(--p-font);
    margin: 0; 
  }

  h1 {
    font-size: var(--h1);
  }

  h3 {
    font-size: var(--h3)
  }

  h4 {
    font-size: var(--h4); color: var(--color-dark);
  }

  h5 {
    font-size: var(--h5);font-family: "Outfit", sans-serif;
  }

  h6 {
    font-size: var(--h6);
  }

  span {
    font-size: var(--span);
    font-weight: 600;
    font-family: 'myfont1'; font-size: 0.9rem;
  }

  strong {
    color: var(--color-c1);
  }
}